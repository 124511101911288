define('hc2-ui/helpers/format-number-points', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var n = params[0];
    if (!isNaN(n)) {
      return n.toLocaleString();
    }
    return n;
  });
});