define("hc2-ui/controllers/external-content", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ["src"],
    host: Ember.computed("src", function () {
      var src = this.get("src");
      return new URL(src).hostname;
    }),
    actions: {
      loadSrc: function loadSrc() {
        if (this.get("allowHostAlways")) {
          var r = window.localStorage.getItem("trustedSources");
          var e = r ? JSON.parse(r) : [];
          e.push(this.get("host"));
          window.localStorage.setItem("trustedSources", JSON.stringify(e));
        } else {
          document.location = this.get("src");
        }
      }
    }
  });
});