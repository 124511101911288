define('hc2-ui/helpers/json-stringify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var pretty = params[1] || false;
    return JSON.stringify(params[0], 0, pretty ? 2 : 0);
  });
});