define('hc2-ui/services/embed', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    device: Ember.inject.service(),
    api: Ember.inject.service(),
    tracking: Ember.inject.service(),
    isEmbedded: false,

    initIframeResizerContentWindow: function initIframeResizerContentWindow() {
      var _this = this;

      window.iFrameResizer = {
        messageCallback: function messageCallback(message) {
          switch (message.messageId) {
            case "parentWindowInfo":
              _this.handleParentWindowInfo(message);
              break;
            case "setGaClientId":
              _this.setGaClientId(message);
              break;
          }
        },
        readyCallback: function readyCallback() {
          if ('parentIFrame' in window) {
            window.parentIFrame.sendMessage({ messageId: "ready" });
          }
        }
      };
      this.get("api").loadScript("/assets/plugins/iframe-resizer/iframeResizer.contentWindow.min.js");
    },
    setGaClientId: function setGaClientId(message) {
      this.set("tracking.gaClientId", message.clientId);
    },
    handleParentWindowInfo: function handleParentWindowInfo(message) {
      var parentWindowOrientation = message.width > message.height ? "landscape" : "portrait";
      this.set("device.parentWindowOrientation", parentWindowOrientation);
      this.set("device.parentWindowWidth", message.width);
      this.set("device.parentWindowHeight", message.height);
      window.hc2ParentWindowHeight = message.height;
    },
    sendContentIdChange: function sendContentIdChange(contentId) {
      if ('parentIFrame' in window) {
        window.parentIFrame.sendMessage({ messageId: "contentIdChange", val: contentId });
      }
    },
    notifyTransition: function notifyTransition() {
      if ('parentIFrame' in window) {
        window.parentIFrame.sendMessage({ messageId: "didTransition", 'location': location.href });
      }
    },
    notifyStepChange: function notifyStepChange() {
      if ('parentIFrame' in window) {
        window.parentIFrame.sendMessage({ messageId: "didStepChange", 'location': location.href });
      }
    },
    notifyEvent: function notifyEvent(event) {
      if ('parentIFrame' in window) {
        window.parentIFrame.sendMessage({ messageId: "event", eventData: event });
      }
    }
  });
});