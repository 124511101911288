define("hc2-ui/templates/components/apps/integrations/inxmail-app-settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 8
            },
            "end": {
              "line": 8,
              "column": 8
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn btn-danger");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element10, 'onClick');
          morphs[1] = dom.createMorphAt(element10, 0, 0);
          return morphs;
        },
        statements: [["attribute", "onClick", ["subexpr", "action", ["deactivateInxmail"], [], ["loc", [null, [null, null], [7, 74]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["deactivate"], [], ["loc", [null, [7, 75], [7, 93]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 8
            },
            "end": {
              "line": 10,
              "column": 8
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "btn btn-success");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element9, 'onClick');
          morphs[1] = dom.createMorphAt(element9, 0, 0);
          return morphs;
        },
        statements: [["attribute", "onClick", ["subexpr", "action", ["activateInxmail"], [], ["loc", [null, [null, null], [9, 73]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["activate"], [], ["loc", [null, [9, 74], [9, 90]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 10
            },
            "end": {
              "line": 25,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "list-name");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-arrow-right text-success");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(": ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("b");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element8, 2, 2);
          morphs[1] = dom.createMorphAt(dom.childAt(element8, [4]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["integrations.recipientList"], [], ["loc", [null, [23, 57], [23, 91]]], 0, 0], ["content", "appData.appIntegrations.inxmail.list.name", ["loc", [null, [23, 97], [23, 142]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 10
            },
            "end": {
              "line": 30,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-check text-success");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-info-circle text-success");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 9, 9, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["integrations.active"], [], ["loc", [null, [28, 50], [28, 77]]], 0, 0], ["inline", "t", ["inxmail.activeInfo"], [], ["loc", [null, [29, 56], [29, 82]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 30,
              "column": 10
            },
            "end": {
              "line": 32,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-times text-danger");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["integrations.inactive"], [], ["loc", [null, [31, 49], [31, 78]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 10
            },
            "end": {
              "line": 41,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(": ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("strong");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["integrations.connectedWith"], [], ["loc", [null, [40, 12], [40, 46]]], 0, 0], ["content", "userIntegrations.inxmail.info.customer", ["loc", [null, [40, 56], [40, 98]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child6 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 10
            },
            "end": {
              "line": 44,
              "column": 10
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-times text-danger");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["integrations.notActivatedForApp"], [], ["loc", [null, [43, 12], [43, 51]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child7 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 12
                },
                "end": {
                  "line": 54,
                  "column": 102
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "fa fa-spinner faa-spin animated");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 12
                },
                "end": {
                  "line": 62,
                  "column": 12
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "select-2", [], ["placeholder", ["subexpr", "t", ["select.please"], [], ["loc", [null, [57, 34], [57, 53]]], 0, 0], "content", ["subexpr", "@mut", [["get", "selectedClientListsOrdered", ["loc", [null, [58, 30], [58, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "name", "value", ["subexpr", "@mut", [["get", "selectedInxmailList", ["loc", [null, [60, 28], [60, 47]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [56, 14], [61, 16]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 12
                },
                "end": {
                  "line": 67,
                  "column": 12
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element5, 'class');
              morphs[1] = dom.createAttrMorph(element5, 'onClick');
              morphs[2] = dom.createMorphAt(element5, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["btn ", ["subexpr", "unless", [["get", "selectedInxmailList", ["loc", [null, [66, 35], [66, 54]]], 0, 0, 0, 0], "disabled", "btn-primary"], [], ["loc", [null, [66, 26], [66, 81]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["activateInxmailList", ["get", "selectedInxmailList", ["loc", [null, [66, 122], [66, 141]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [66, 143]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["mailchimp.activateList"], [], ["loc", [null, [66, 144], [66, 174]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 8
              },
              "end": {
                "line": 70,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "inxmail-recipient-list-selector");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(":");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "recipient-list");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "connect-button");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [3]);
            var element7 = dom.childAt(element6, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element7, 1, 1);
            morphs[2] = dom.createMorphAt(element7, 3, 3);
            morphs[3] = dom.createMorphAt(dom.childAt(element6, [5]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["integrations.recipientList"], [], ["loc", [null, [51, 17], [51, 51]]], 0, 0], ["block", "if", [["get", "selectedClientLists.isPending", ["loc", [null, [54, 18], [54, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [54, 12], [54, 109]]]], ["block", "if", [["get", "selectedClientLists", ["loc", [null, [55, 18], [55, 37]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [55, 12], [62, 19]]]], ["block", "if", [["get", "selectedInxmailList", ["loc", [null, [65, 18], [65, 37]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [65, 12], [67, 19]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 8
            },
            "end": {
              "line": 71,
              "column": 8
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "appData.appIntegrations.inxmail.list", ["loc", [null, [48, 18], [48, 54]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [48, 8], [70, 19]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child8 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 14
                },
                "end": {
                  "line": 91,
                  "column": 14
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "input", [], ["placeholder", ["subexpr", "t", ["question.type.hiddenfield"], [], ["loc", [null, [87, 38], [87, 69]]], 0, 0], "class", "form-control floating-label", "value", ["subexpr", "@mut", [["get", "mapping.value", ["loc", [null, [89, 33], [89, 46]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [87, 18], [89, 48]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 75,
                "column": 8
              },
              "end": {
                "line": 110,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row m-b-5");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-5");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-1 text-center");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-arrow-right m-t-5");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-5");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-1 text-right");
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "class", "pointer text-danger");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "fa fa-trash m-t-5");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            var element2 = dom.childAt(element0, [7, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(element1, 1, 1);
            morphs[1] = dom.createMorphAt(element1, 3, 3);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
            morphs[3] = dom.createAttrMorph(element2, 'onclick');
            return morphs;
          },
          statements: [["inline", "select-2", [], ["placeholder", ["subexpr", "t", ["emarsys.appField"], [], ["loc", [null, [79, 32], [79, 54]]], 0, 0], "content", ["subexpr", "@mut", [["get", "appFields", ["loc", [null, [80, 28], [80, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "label", "value", ["subexpr", "@mut", [["get", "mapping.source", ["loc", [null, [83, 26], [83, 40]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [78, 12], [84, 14]]], 0, 0], ["block", "if", [["subexpr", "is-eq", [["get", "mapping.source", ["loc", [null, [85, 27], [85, 41]]], 0, 0, 0, 0], "hiddenfield"], [], ["loc", [null, [85, 20], [85, 56]]], 0, 0]], [], 0, null, ["loc", [null, [85, 14], [91, 21]]]], ["inline", "select-2", [], ["placeholder", ["subexpr", "t", ["inxmail.inxmailField"], [], ["loc", [null, [98, 32], [98, 58]]], 0, 0], "content", ["subexpr", "@mut", [["get", "selectedClientListAttributesOptions", ["loc", [null, [99, 28], [99, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "optionValuePath", "id", "optionLabelPath", "label", "optionDescriptionPath", "type", "value", ["subexpr", "@mut", [["get", "mapping.dest", ["loc", [null, [103, 26], [103, 38]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [97, 12], [104, 14]]], 0, 0], ["attribute", "onclick", ["subexpr", "action", ["deleteMapping", ["get", "mapping", ["loc", [null, [107, 78], [107, 85]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [107, 86], [107, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [107, 93]]], 0, 0], 0, 0, 0, 0]],
          locals: ["mapping", "index"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 8
            },
            "end": {
              "line": 117,
              "column": 8
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "m-t-10 text-right");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "text-success pointer");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "fa fa-plus");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "m-t-10");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "btn btn-success");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [5, 1]);
          var element4 = dom.childAt(fragment, [7, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createAttrMorph(element3, 'onclick');
          morphs[3] = dom.createMorphAt(element3, 1, 1);
          morphs[4] = dom.createAttrMorph(element4, 'onclick');
          morphs[5] = dom.createMorphAt(element4, 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["inxmail.mappingHeader"], [], ["loc", [null, [73, 12], [73, 41]]], 0, 0], ["block", "each", [["get", "appData.appIntegrations.inxmail.list.mapping", ["loc", [null, [75, 16], [75, 60]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [75, 8], [110, 17]]]], ["attribute", "onclick", ["subexpr", "action", ["addMapping", ["get", "mapping", ["loc", [null, [112, 72], [112, 79]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [112, 80], [112, 85]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [112, 87]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["mapping.add"], [], ["loc", [null, [112, 114], [112, 133]]], 0, 0], ["attribute", "onclick", ["subexpr", "action", ["saveMapping"], [], ["loc", [null, [null, null], [115, 69]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["emarsys.saveMapping"], [], ["loc", [null, [115, 70], [115, 97]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 122,
            "column": 0
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/integrations/inxmail-app-settings.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "panel panel-integrations panel-inxmail");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel-header");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "src", "/assets/images/logo/Inxmail-Logo-200.png");
        dom.setAttribute(el4, "class", "integration_logo");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "pull-right");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "panel-content");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-sm-12");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "integration-overall-status status-line");
        var el6 = dom.createTextNode("\n\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "integration-overall-status status-line");
        var el6 = dom.createTextNode("\n\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "integration-status status-line");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [0]);
        var element12 = dom.childAt(element11, [3, 1, 1]);
        var element13 = dom.childAt(element12, [1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 1, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(element13, 1, 1);
        morphs[2] = dom.createMorphAt(element13, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element12, [5]), 1, 1);
        morphs[4] = dom.createMorphAt(element12, 7, 7);
        morphs[5] = dom.createMorphAt(element12, 8, 8);
        return morphs;
      },
      statements: [["block", "if", [["get", "appData.appIntegrations.inxmail.active", ["loc", [null, [6, 14], [6, 52]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 8], [10, 15]]]], ["block", "if", [["get", "appData.appIntegrations.inxmail.list", ["loc", [null, [21, 16], [21, 52]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [21, 10], [25, 17]]]], ["block", "if", [["get", "appData.appIntegrations.inxmail.active", ["loc", [null, [27, 16], [27, 54]]], 0, 0, 0, 0]], [], 3, 4, ["loc", [null, [27, 10], [32, 17]]]], ["block", "if", [["get", "userIntegrations.inxmail.active", ["loc", [null, [39, 16], [39, 47]]], 0, 0, 0, 0]], [], 5, 6, ["loc", [null, [39, 10], [44, 17]]]], ["block", "if", [["get", "showInxmailForm", ["loc", [null, [47, 14], [47, 29]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [47, 8], [71, 15]]]], ["block", "if", [["get", "appData.appIntegrations.inxmail.list.id", ["loc", [null, [72, 14], [72, 53]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [72, 8], [117, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  }());
});