define("hc2-ui/components/apps/test/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content", "hc2-ui/lib/constants/global-app-translations"], function (exports, _init, _hasHtmlContent, _globalAppTranslations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: {},

    appStates: {
      showWelcomePage: false
    },
    editableSubAppLabels: ["appdata_participate_nav_label"],
    editableLabels: (0, _globalAppTranslations.getEditableLabels)("test"),

    selectedQuestion: Ember.computed.alias("appStates.selectedQuestion"),
    selectedResult: Ember.computed.alias("appStates.selectedResult"),
    selectedExtraFromElement: 0,

    save: "save",
    actions: {
      save: function save() {
        if (this.get('appData.noForm')) {
          this.set('appData.impressum', '-');
          this.set('appData.conditions', '-');
        }
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setQuestionImageFromUpload: function setQuestionImageFromUpload(url, question) {
        Ember.set(question, 'headerImageUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      selectParticipationPage: function selectParticipationPage() {
        this.switchWelcomePageIfActive(false);
        this.set("selectedQuestion", this.get('appData.questions.length'));
      },
      selectResultsPage: function selectResultsPage() {
        this.switchWelcomePageIfActive(false);
        this.set("selectedResult", 0);
        this.set("selectedQuestion", this.get("appData.questions.length") + (this.get("appData.noForm") ? 0 : 1));
      },
      selectResult: function selectResult(index) {
        this.set("selectedResult", index);
      },
      addResult: function addResult() {
        this.get('appData.results').pushObject({});
        this.set("selectedResult", this.get('appData.results.length') - 1);
      },
      removeResult: function removeResult(index) {
        this.get('appData.results').removeAt(index);
        this.set("selectedResult", 0);
      },

      selectQuestionsPage: function selectQuestionsPage() {
        this.switchWelcomePageIfActive(false);
        if (this.get('selectedQuestion') >= this.get('appData.questions.length')) {
          this.set("selectedQuestion", 0);
        }
      },
      selectQuestion: function selectQuestion(index) {
        this.set("selectedQuestion", index);
      },
      addQuestion: function addQuestion() {
        this.get('appData.questions').pushObject({ type: "radio", mandetory: true, answers: [] });
        this.set("selectedQuestion", this.get('appData.questions.length') - 1);
      },
      removeQuestion: function removeQuestion(questionIndex) {
        this.get('appData.questions').removeAt(questionIndex);
        this.set("selectedExtraFromElement", 0);
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject(Object.create({ label: "", correct: false }));
        this.get("appData").notifyPropertyChange("contactData");
      },
      refreshContactData: function refreshContactData() {
        this.get("appData").notifyPropertyChange("contactData");
      },

      removeAnswer: function removeAnswer(question, answerIndex) {
        question.answers.removeAt(answerIndex);
      },
      setCategoryForAnswer: function setCategoryForAnswer(answer, index, event) {
        if (answer.categories === undefined) {
          answer.categories = [];
        }
        if (event.target.checked) {
          answer.categories.pushObject(index);
        } else {
          answer.categories.removeObject(index);
        }
      },

      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
        return true;
      },
      setCountDownDuration: function setCountDownDuration(on) {
        this.set("appData.countDownDuration", on ? 60 : 0);
      }
    },

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },

    didRender: function didRender() {
      _init.default.material(this.elementId);
    },
    showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
      return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
    }),
    setupModel: function () {
      this.set("selectedQuestion", 0);

      if (!this.get('appData.questions')) {
        this.set('appData.questions', Ember.A([]));
      }
      if (!this.get('appData.results')) {
        this.set('appData.results', Ember.A([]));
      }
      if (!this.get('appData.contactData')) {
        this.set('appData.contactData', {
          salutationMandetory: true,
          nameMandetory: true,
          firstName: true,
          firstNameMandetory: true,
          lastName: true,
          lastNameMandetory: true,
          email: true,
          emailMandetory: true,
          extraFromElements: []
        });
      }
    }.on("init")

  });
});