define('hc2-ui/components/apps/wincodes-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    api: Ember.inject.service(),
    session: Ember.inject.service(),
    inDeletion: "none",

    codesToImportCount: Ember.computed('codesToImport', function () {
      var codes = this.get('codesToImport');
      if (codes) {
        return this.get('codesToImport').split(/\r|\r\n|\n/).length;
      }
      return 0;
    }),

    uploadQueryString: Ember.computed('appData.id', 'prizeId', function () {
      return "appId=" + this.get('appData.id') + "&prizeId=" + this.get('prizeId') + '&filetype=wincodes';
    }),

    prizesWithIndexes: Ember.computed('appData.prizes', function () {
      var prizes = [];
      this.get('appData.prizes').forEach(function (v, i) {
        prizes.push({ text: v.text, id: i });
      });
      return prizes;
    }),

    actions: {
      uploadFailed: function uploadFailed(data) {
        window.alert("import failed \n" + data.responseText);
      },
      uploadFinished: function uploadFinished(data) {
        this.updateWinCodesTillUploadDone(0);
        this.set('prizeId', undefined);
        window.alert(this.get("i18n").t("winCodes.fileUploadFinished"));
      },
      deleteWinCodes: function deleteWinCodes(prize) {
        var _this = this;

        this.set("inDeletion", prize);
        if (window.confirm(this.get("i18n").t("confirm"))) {
          this.get("api").request("/appCode/deleteWincodes/" + this.get('appData.id') + "?prize=" + prize).then(function () {
            _this.loadWinCodes();
          });
        }
      }
    },

    didInsertElement: function didInsertElement() {
      if (this.get('appData.id')) {
        this.loadWinCodes();
      }
    },
    loadWinCodes: function loadWinCodes() {
      var _this2 = this;

      this.get("api").requestJSON("/appCode/getWinCodeCount/" + this.get('appData.id')).then(function (winCodesPerPrize) {
        _this2.set("winCodesPerPrize", winCodesPerPrize);
      });
    },
    updateWinCodesTillUploadDone: function updateWinCodesTillUploadDone(loop) {
      var _this3 = this;

      if (loop > 2) {
        return;
      }
      var lastTotal = this.getTotelWinCodes(this.get("winCodesPerPrize"));
      this.get("api").requestJSON("/appCode/getWinCodeCount/" + this.get('appData.id')).then(function (winCodesPerPrize) {
        var newCount = _this3.getTotelWinCodes(winCodesPerPrize);
        if (lastTotal == newCount) {
          loop++;
        } else {
          _this3.set("winCodesPerPrize", winCodesPerPrize);
        }
        Ember.run.later(function () {
          _this3.updateWinCodesTillUploadDone(loop);
        }, 1000);
      });
    },
    getTotelWinCodes: function getTotelWinCodes(codes) {
      var total = 0;
      codes.forEach(function (codeCat) {
        total += codeCat[1];
      });
      return total;
    }
  });
});