define("hc2-ui/lib/constants/global-app-translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getEditableLabels = getEditableLabels;
  function getEditableLabels(appType) {
    var labels = [];
    editableLabels.forEach(function (label) {
      if (!label.apps || label.apps.indexOf(appType) > -1) {
        labels.push(label.label);
      }
    });
    return labels;
  }

  var editableLabels = [{
    "label": "impressum"
  }, {
    "label": "overview",
    "apps": ["sweepstake", "contest", "quiz", "poll", "pixelGraphic", "test", "winWheel", "slotMachine", "scratchCard", "memory", "game", "codePromotion"]
  }, {
    "label": "sweepstake_participate",
    "apps": ["sweepstake", "christmasCalendar", "calendar", "quiz", "poll", "pixelGraphic", "test", "winWheel", "slotMachine", "scratchCard", "packYourBag", "memory", "game", "codePromotion"]
  }, {
    "label": "appdata_participate_nav_label",
    "apps": ["sweepstake", "contest", "quiz", "poll", "pixelGraphic", "test", "winWheel", "slotMachine", "scratchCard", "memory", "game", "codePromotion"]
  }, {
    "label": "close"
  }, {
    "label": "forward",
    "apps": ["sweepstake", "contest", "christmasCalendar", "calendar", "calendar", "quiz", "poll", "test", "winWheel", "slotMachine", "scratchCard", "packYourBag", "packYourBag", "codePromotion"]
  }, {
    "label": "back",
    "apps": ["sweepstake", "contest", "christmasCalendar", "calendar", "calendar", "quiz", "poll", "test", "winWheel", "slotMachine", "scratchCard", "packYourBag", "packYourBag", "codePromotion"]
  }, {
    "label": "yes"
  }, {
    "label": "no"
  }, {
    "label": "save",
    "apps": ["sweepstake", "contest", "winWheel", "slotMachine", "scratchCard", "codePromotion"]
  }, {
    "label": "salutation_male"
  }, {
    "label": "salutation_female"
  }, {
    "label": "salutation_diverse"
  }, {
    "label": "sweepstake_contact_firstName"
  }, {
    "label": "sweepstake_contact_lastName"
  }, {
    "label": "sweepstake_contact_name"
  }, {
    "label": "sweepstake_contact_email"
  }, {
    "label": "sweepstake_contact_salutation"
  }, {
    "label": "sweepstake_contact_phone"
  }, {
    "label": "sweepstake_contact_mobile"
  }, {
    "label": "sweepstake_contact_street"
  }, {
    "label": "sweepstake_contact_streetNr"
  }, {
    "label": "sweepstake_contact_zip"
  }, {
    "label": "sweepstake_contact_city"
  }, {
    "label": "sweepstake_contact_country"
  }, {
    "label": "sweepstake_contact_birthdate"
  }, {
    "label": "sweepstake_contact_doiLabel"
  }, {
    "label": "conditionsAgree_before"
  }, {
    "label": "conditions"
  }, {
    "label": "conditionsAgree_middle"
  }, {
    "label": "privacyPolicy"
  }, {
    "label": "conditionsAgree_after"
  }, {
    "label": "mandetory_field_hint_checkbox"
  }, {
    "label": "mandetory_field_hint_text"
  }, {
    "label": "mandetory_field_hint_radio"
  }, {
    "label": "mandetory_field_hint_hidden"
  }, {
    "label": "mandetory_field"
  }, {
    "label": "participation_error_invalidEmail_sweepstake_participation",
    "apps": ["sweepstake", "contest", "christmasCalendar", "calendar", "quiz", "poll", "pixelGraphic", "test", "winWheel", "slotMachine", "packYourBag", "memory", "game", "codePromotion"]
  }, {
    "label": "constraint_unique_uniqueId_sweepstake_participation",
    "apps": ["sweepstake", "sweepstake", "contest", "christmasCalendar", "christmasCalendar", "calendar", "calendar", "quiz", "quiz", "poll", "poll", "pixelGraphic", "pixelGraphic", "test", "test", "winWheel", "winWheel", "slotMachine", "slotMachine", "scratchCard", "scratchCard", "packYourBag", "packYourBag", "memory", "memory", "game", "game", "codePromotion"]
  }, {
    "label": "mandetory_field_patternHint_text"
  }, {
    "label": "backToOverview",
    "apps": ["contest"]
  }, {
    "label": "participate",
    "apps": ["contest"]
  }, {
    "label": "search",
    "apps": ["contest"]
  }, {
    "label": "category_select",
    "apps": ["contest", "contest"]
  }, {
    "label": "sortProperty_title",
    "apps": ["contest"]
  }, {
    "label": "sortProperty_rating",
    "apps": ["contest"]
  }, {
    "label": "sortProperty_views",
    "apps": ["contest"]
  }, {
    "label": "sortProperty_dateCreated",
    "apps": ["contest"]
  }, {
    "label": "participations_none_yet",
    "apps": ["contest"]
  }, {
    "label": "participations_all_loaded",
    "apps": ["contest"]
  }, {
    "label": "preview",
    "apps": ["contest"]
  }, {
    "label": "contestParticipation_step_commons",
    "apps": ["contest"]
  }, {
    "label": "contestParticipation_step_profile",
    "apps": ["contest"]
  }, {
    "label": "contestParticipation_step_save",
    "apps": ["contest"]
  }, {
    "label": "mediaType_select",
    "apps": ["contest"]
  }, {
    "label": "mediaType_single_photo",
    "apps": ["contest"]
  }, {
    "label": "mediaType_single_video",
    "apps": ["contest"]
  }, {
    "label": "mediaType_single_text",
    "apps": ["contest"]
  }, {
    "label": "image_select",
    "apps": ["contest"]
  }, {
    "label": "image_selectExtra",
    "apps": ["contest"]
  }, {
    "label": "upload_action",
    "apps": ["contest"]
  }, {
    "label": "image_mandetory_message",
    "apps": ["contest"]
  }, {
    "label": "video_selectUrl",
    "apps": ["contest"]
  }, {
    "label": "video_url",
    "apps": ["contest"]
  }, {
    "label": "upload_video",
    "apps": ["contest"]
  }, {
    "label": "video_mandetory_message",
    "apps": ["contest"]
  }, {
    "label": "title_enter",
    "apps": ["contest"]
  }, {
    "label": "title",
    "apps": ["contest"]
  }, {
    "label": "description_add",
    "apps": ["contest"]
  }, {
    "label": "vote_plural",
    "apps": ["contest"]
  }, {
    "label": "views",
    "apps": ["contest"]
  }, {
    "label": "vote_action",
    "apps": ["contest"]
  }, {
    "label": "vote_emailActivation_action",
    "apps": ["contest"]
  }, {
    "label": "vote_already_voted",
    "apps": ["contest"]
  }, {
    "label": "vote_thanks_header",
    "apps": ["contest"]
  }, {
    "label": "vote_thanks_text",
    "apps": ["contest"]
  }, {
    "label": "vote_emailActivation_header",
    "apps": ["contest"]
  }, {
    "label": "vote_emailActivation_text",
    "apps": ["contest"]
  }, {
    "label": "vote_activate_header",
    "apps": ["contest"]
  }, {
    "label": "vote_activate_text",
    "apps": ["contest"]
  }, {
    "label": "vote_activated_header",
    "apps": ["contest"]
  }, {
    "label": "vote_activated_text",
    "apps": ["contest"]
  }, {
    "label": "christmasCalendar_toEarly",
    "apps": ["christmasCalendar", "calendar", "packYourBag"]
  }, {
    "label": "christmasCalendar_toLate",
    "apps": ["christmasCalendar", "calendar", "packYourBag"]
  }, {
    "label": "sweepstake_contact_mailingsLabel",
    "apps": ["christmasCalendar"]
  }, {
    "label": "game_start",
    "apps": ["christmasCalendar", "pixelGraphic"]
  }, {
    "label": "game_proceed",
    "apps": ["christmasCalendar", "pixelGraphic", "game"]
  }, {
    "label": "game_replay",
    "apps": ["christmasCalendar", "pixelGraphic", "game"]
  }, {
    "label": "memory_resetApp",
    "apps": ["christmasCalendar", "memory"]
  }, {
    "label": "pixelGraphic_correctAnswer",
    "apps": ["christmasCalendar", "pixelGraphic"]
  }, {
    "label": "pixelGraphic_wrongAnswer",
    "apps": ["christmasCalendar", "pixelGraphic"]
  }, {
    "label": "test_answer_commit",
    "apps": ["calendar", "christmasCalendar", "quiz", "poll", "test", "packYourBag"]
  }, {
    "label": "test_toResult",
    "apps": ["calendar", "christmasCalendar", "quiz", "poll", "test", "packYourBag"]
  }, {
    "label": "question_result_true",
    "apps": ["calendar", "christmasCalendar", "quiz", "poll", "test", "packYourBag"]
  }, {
    "label": "question_result_false",
    "apps": ["calendar", "christmasCalendar", "quiz", "poll", "test", "packYourBag"]
  }, {
    "label": "points_label",
    "apps": ["calendar", "christmasCalendar", "quiz", "poll", "test", "packYourBag"]
  }, {
    "label": "timer_done",
    "apps": ["quiz"]
  }, {
    "label": "winWheel_win",
    "apps": ["winWheel", "slotMachine", "scratchCard"]
  }, {
    "label": "slotMachine_contactDataHeader",
    "apps": ["slotMachine"]
  }, {
    "label": "slotMachine_stopNext",
    "apps": ["slotMachine"]
  }, {
    "label": "start",
    "apps": ["slotMachine"]
  }, {
    "label": "reset",
    "apps": ["slotMachine"]
  }, {
    "label": "scratchCard_participateToGoOn",
    "apps": ["scratchCard"]
  }, {
    "label": "scratchCard_rubFree",
    "apps": ["scratchCard"]
  }, {
    "label": "game_points",
    "apps": ["game"]
  }, {
    "label": "codePromotion_inputPlaceholder",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_invalidCode",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_codeMissing",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_captchaMissing",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_submitContactData",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_participate",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_error_codeAlreadyUsed_sweepstake_participation",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_error_codeInvalid_sweepstake_participation",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_error_drawServerError",
    "apps": ["codePromotion"]
  }, {
    "label": "codePromotion_resetApp",
    "apps": ["codePromotion"]
  }, {
    "label": "consent-gate_src-host-info"
  }, {
    "label": "consent-gate_load-src"
  }, {
    "label": "consent-gate_accept-always"
  }, {
    "label": "vote_already_votedMax",
    "apps": ["contest"]
  }];
});