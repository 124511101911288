define("hc2-ui/templates/components/controls/capt-check", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 36,
                "column": 8
              }
            },
            "moduleName": "hc2-ui/templates/components/controls/capt-check.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("input");
            dom.setAttribute(el2, "type", "radio");
            dom.setAttribute(el2, "name", "captcheck_selected_answer");
            dom.setAttribute(el2, "required", "true");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1]);
            if (this.cachedFragment) {
              dom.repairClonedNode(element1, [], true);
            }
            var element2 = dom.childAt(element0, [3]);
            var morphs = new Array(6);
            morphs[0] = dom.createAttrMorph(element0, 'onclick');
            morphs[1] = dom.createAttrMorph(element0, 'class');
            morphs[2] = dom.createAttrMorph(element1, 'id');
            morphs[3] = dom.createAttrMorph(element1, 'value');
            morphs[4] = dom.createAttrMorph(element1, 'checked');
            morphs[5] = dom.createAttrMorph(element2, 'src');
            return morphs;
          },
          statements: [["attribute", "onclick", ["subexpr", "action", ["selectAnswer", ["get", "answer", ["loc", [null, [25, 45], [25, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [25, 53]]], 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["captcheck_answer_label ", ["subexpr", "if", [["subexpr", "is-eq", [["get", "answer", ["loc", [null, [26, 58], [26, 64]]], 0, 0, 0, 0], ["get", "selectedAnswer", ["loc", [null, [26, 65], [26, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 51], [26, 80]]], 0, 0], "selected_answer"], [], ["loc", [null, [26, 46], [26, 100]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["concat", ["captcheck_", ["get", "challenge.id_prefix", ["loc", [null, [27, 35], [27, 54]]], 0, 0, 0, 0], "_answer_", ["get", "answer", ["loc", [null, [27, 66], [27, 72]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["concat", [["get", "answer", ["loc", [null, [30, 28], [30, 34]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "checked", ["subexpr", "is-eq", [["get", "answer", ["loc", [null, [31, 35], [31, 41]]], 0, 0, 0, 0], ["get", "selectedAnswer", ["loc", [null, [31, 42], [31, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [31, 58]]], 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "apiUrl", ["loc", [null, [34, 24], [34, 30]]], 0, 0, 0, 0], "?action=img&s=", ["get", "challenge.session", ["loc", [null, [34, 48], [34, 65]]], 0, 0, 0, 0], "&c=", ["get", "answer", ["loc", [null, [34, 72], [34, 78]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: ["answer"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 4
            },
            "end": {
              "line": 39,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/controls/capt-check.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "captcheck-reload");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa icon-reload");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "captcheck_answer_images");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element4, [1]);
          var element6 = dom.childAt(element4, [3]);
          var element7 = dom.childAt(element3, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element3, 'class');
          morphs[1] = dom.createMorphAt(element5, 1, 1);
          morphs[2] = dom.createMorphAt(element5, 3, 3);
          morphs[3] = dom.createAttrMorph(element6, 'title');
          morphs[4] = dom.createAttrMorph(element6, 'onclick');
          morphs[5] = dom.createAttrMorph(element7, 'id');
          morphs[6] = dom.createMorphAt(element7, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "showChallenge", ["loc", [null, [16, 21], [16, 34]]], 0, 0, 0, 0], "fadeInUp animated", "hidden"], [], ["loc", [null, [16, 16], [16, 65]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "ta", ["captcheck.chooseIcon"], [], ["loc", [null, [19, 8], [19, 37]]], 0, 0], ["inline", "ta", [["subexpr", "concat", ["captcheck.icons.", ["subexpr", "replace", [["get", "challenge.question_o", ["loc", [null, [19, 79], [19, 99]]], 0, 0, 0, 0], " ", "_"], [], ["loc", [null, [19, 70], [19, 108]]], 0, 0]], [], ["loc", [null, [19, 43], [19, 109]]], 0, 0]], [], ["loc", [null, [19, 38], [19, 111]]], 0, 0], ["attribute", "title", ["concat", [["subexpr", "ta", ["captcheck.reload"], [], ["loc", [null, [21, 43], [21, 68]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["reloadChallenge"], [], ["loc", [null, [null, null], [21, 106]]], 0, 0], 0, 0, 0, 0], ["attribute", "id", ["concat", ["captcheck_", ["get", "challenge.id_prefix", ["loc", [null, [23, 59], [23, 78]]], 0, 0, 0, 0], "_answer_images"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "challenge.answers", ["loc", [null, [24, 16], [24, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [24, 8], [36, 17]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/controls/capt-check.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-group");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "captcheck-wrapper captcheck_box");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "checkbox-text captcheck_label_message");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(".\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("input");
        dom.setAttribute(el2, "type", "hidden");
        dom.setAttribute(el2, "name", "captcheck_session_code");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element8 = dom.childAt(fragment, [0]);
        var element9 = dom.childAt(element8, [1]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element8, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element10, 'class');
        morphs[1] = dom.createMorphAt(element11, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
        morphs[3] = dom.createMorphAt(element9, 3, 3);
        morphs[4] = dom.createAttrMorph(element12, 'value');
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["no_robot_checkbox checkbox checkbox-primary ", ["subexpr", "if", [["get", "showChallenge", ["loc", [null, [5, 65], [5, 78]]], 0, 0, 0, 0], "fadeOutDown animated absolute"], [], ["loc", [null, [5, 60], [5, 112]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["name", "captcha_accept", "checked", ["subexpr", "@mut", [["get", "showChallenge", ["loc", [null, [7, 46], [7, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "checkbox", "class", "md-checkbox", "required", true], ["loc", [null, [7, 8], [7, 111]]], 0, 0], ["inline", "ta", ["captcheck.notARobot"], [], ["loc", [null, [9, 10], [9, 39]]], 0, 0], ["block", "if", [["get", "challenge", ["loc", [null, [15, 10], [15, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [15, 4], [39, 11]]]], ["attribute", "value", ["concat", [["get", "challenge.session", ["loc", [null, [41, 62], [41, 79]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});