define('hc2-ui/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

    session: Ember.inject.service('session'),
    embed: Ember.inject.service(),
    i18n: Ember.inject.service(),
    config: Ember.inject.service(),
    device: Ember.inject.service(),
    tracking: Ember.inject.service(),
    headData: Ember.inject.service(),

    beforeModel: function beforeModel() {
      this.get("i18n").initLocale();
      this.set("headData.title", window.hc2InitialAppTitle);
      this.set("pageTitle", window.hc2InitialAppTitle);

      if (this.get("config").getUrlParameters().hasOwnProperty("e")) {
        this.set("embed.isEmbedded", true);
      }
    },
    model: function model(params) {
      var _this2 = this;

      if (params.orientation) {
        this.set("device.paramOrientation", params.orientation);
      }
      if (params.source) {
        this.set("session.source", params.source);
      }
      return this.store.findRecord("platform", window.location.hostname).then(function (platform) {
        _this2.get("config").set("platform", platform);
        return platform;
      });
    },

    sessionInvalidated: function sessionInvalidated() {},
    afterModel: function afterModel() {
      // auto redirect to user setting target if present
      var isApp = this.get('config.platform.appId');
      if (!isApp) {
        this.get("tracking").initThirdPartyScripts(false, true, true, true);
      }
      this.set("lastTs", window.localStorage.getItem("trustedSources"));
      setInterval(this.checkTrustedSources, 1000, this);
    },
    checkTrustedSources: function checkTrustedSources(_this) {
      var ts = window.localStorage.getItem("trustedSources");
      var lastTs = _this.get("lastTs");
      if (lastTs !== ts) {
        _this.get("i18n").set("forceRecompute", Date.now());
        console.log("forceRecompute");
      }
      _this.set("lastTs", ts);
    }
  });
});