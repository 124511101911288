define('hc2-ui/routes/backend/apps/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    defaults: Ember.inject.service(),
    i18n: Ember.inject.service(),
    model: function model(params) {
      var m = this.get('defaults').getDefaultAppEditorModel(params.appType_id);
      var defaultHook = this.modelFor("backend").userIntegrations.get("gHook.info.hookApiUrl");
      if (defaultHook) {
        m.appData.set('hookApiUrl', defaultHook);
      }
      return m;
    },
    afterModel: function afterModel(model) {
      this.set("i18n.app", model.appData);
      this.set("pageTitle", [this.get("i18n").t('app.' + model.appData.get("appType")), this.get("i18n").t('editor.mode.create')]);
    }
  });
});