define("hc2-ui/components/apps/contest/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/lib/constants/global-app-translations"], function (exports, _init, _globalAppTranslations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: {},

    appStates: {
      showWelcomePage: false
    },
    editableLabels: (0, _globalAppTranslations.getEditableLabels)("contest"),
    editableLabelsWithEditor: ['vote_emailActivation_text', 'vote_activated_text', 'vote_activate_text', 'vote_thanks_text'],
    editableLabelsWithCustomText: ['vote_emailActivation_header', 'vote_emailActivation_text', 'vote_activate_header', 'vote_activate_text', 'vote_activated_header', 'vote_activated_text', 'vote_thanks_header', 'vote_thanks_text'],

    editableVoteActivationEmailLabels: ["emails_voteActivation_subject", "emails_voteActivation_text"],
    editableVoteActivationEmailLabelsWithEditor: ["emails_voteActivation_text"],

    selectedExtraFromElement: 0,

    save: "save",
    actions: {
      addCategory: function addCategory() {
        this.get("appData.categories").pushObject({ value: "" });
      },
      removeCategory: function removeCategory(index) {
        this.get("appData.categories").removeAt(index);
      },
      save: function save() {
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setOverlayImageFromUpload: function setOverlayImageFromUpload(url) {
        this.set('appData.overlayImageUri', url);
      },
      setBacklayImageFromUpload: function setBacklayImageFromUpload(url) {
        this.set('appData.backlayImageUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
        _init.default.material();
      },
      selectParticipationPage: function selectParticipationPage() {
        this.switchWelcomePageIfActive(false);
        this.set("selectedQuestion", this.get('appData.questions.length'));
        this.set("appStates.selectedTab", "participate");
      },
      addAnswer: function addAnswer(question) {
        question.answers.pushObject({ label: "", correct: false });
      },
      removeAnswer: function removeAnswer(question, answerIndex) {
        question.answers.removeAt(answerIndex);
      },
      setHighlightTop: function setHighlightTop(on) {
        this.set("appData.highlightTop", on ? 10 : 0);
      },

      setHighlightImageFromUpload: function setHighlightImageFromUpload(url) {
        this.set('appData.highlightImageUri', url);
      }
    },

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },

    didRender: function didRender() {
      //MakeInit.material(this.elementId);
    },
    didInsertElement: function didInsertElement() {
      //this.$(".contest-editor-form > .nav li:nth-child(5) a").click();
    }
  });
});