define('hc2-ui/components/apps/app-statistics', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    api: Ember.inject.service(),

    classNames: ['app-statistics'],

    appData: null,
    statistic: null,

    childAppsSorting: ['childAppIndex:asc'],
    sortedChildApps: Ember.computed.sort('appData.childApps', 'childAppsSorting'),

    uploadsExportUrl: Ember.computed("appData.id", function () {
      return this.get('api').getApiUrl("/upload/exportAsZip/" + this.get("appData.id"));
    }),

    actions: {
      updateStatsTill: function updateStatsTill(date) {
        var till = (0, _moment.default)(date).set({ "hour": 23, "minute": 59, "second": 59, "millisecond": 999 }).toDate();
        this.set("statsTill", till);
      },
      toggleUseRawData: function toggleUseRawData() {
        this.set("useRawData", !this.get("useRawData"));
      },
      deleteViews: function deleteViews(appData) {
        if (appData.get("id") == window.prompt(this.get("i18n").t("editor.deleteViewsForAppWarningWithIdPrompt", { title: appData.get("title"), id: appData.get("id") }))) {
          var action = this.get('store').createRecord("action", { action: "deleteViewsForApp", context: { id: appData.get('id'), value: appData.get('active') } });
          action.save().then(function () {
            window.location.reload();
          }, function () {
            alert("action failed");
          });
        }
      },
      getRandomParticipaation: function getRandomParticipaation(appId) {
        var _this = this;

        this.get("api").request("/search/randomParticipation/" + appId).then(function (response) {
          _this.get("store").findRecord("participation", response.participationId).then(function (participation) {
            window.$('#' + _this.elementId + ' #random-participation-view').modal();
            participation.get("app").then(function () {
              _this.set("randomParticipation", participation);
            });
          });
        });
      },
      deleteParticipations: function deleteParticipations(appData) {
        if (window.confirm(this.get("i18n").t("editor.deleteParticipationsWarning", { title: appData.get("title"), id: appData.get("id") }))) {
          this.get('store').createRecord("action", { action: "deleteParticipations", context: { appId: appData.get("id") } }).save().then(function () {
            window.location.reload();
          });
        }
      },
      addCodes: function addCodes(codes) {
        var _this2 = this;

        if (window.confirm(this.get("i18n").t("confirm"))) {
          this.get("api").post("/appCode/importCodes", {
            "codes": codes,
            "appId": this.get("appData.id")
          }).then(function (response) {
            window.alert("Imported:\n" + JSON.stringify(response.success) + "\n\n" + "Already Present:\n" + JSON.stringify(response.alreadyPresent) + "\n\n" + "Errors:\n" + JSON.stringify(response.errors) + "\n\n");
            _this2.get("statistic").reload();
          }).fail(function (errorResponse) {
            window.alert("error");
          });
        }
      },
      startExport: function startExport(exportUri) {
        var _this3 = this;

        this.get("api").request(exportUri, { inBackground: true }).then(function (response) {
          _this3.set("generatingDownloadData", response);
          var modal = window.$('#' + _this3.elementId + ' #generating-download').modal({
            keyboard: false,
            backdrop: 'static'
          }).on('hidden.bs.modal', function () {
            _this3.set("api.abortWaitForFile", response.url);
          });

          // wait till generated
          _this3.get("api").waitForFile(response.url).then(function () {
            _this3.get("api").download(response.url, true);
            window.$('#' + _this3.elementId + ' #generating-download').modal('hide');
          }).catch(function () {
            console.log("Cancelled download of " + response.url);
          });
        });
      }
    },
    statsFrom: null,
    statsTill: null,
    loadStatistics: function () {
      var that = this;
      var raw = this.get("useRawData");
      var from = this.get("statsFrom");
      var till = this.get("statsTill");
      var appId = this.get("appData.id");
      this.get('store').queryRecord('app-statistic', { id: appId, raw: raw, from: from.getTime(), till: till.getTime() }).then(function (stats) {
        var todayStart = new Date();
        todayStart.setHours(0, 0, 0, 0);
        that.set("statistic", stats);
        that.loadGraph();
      });
    }.observes('statsFrom', 'statsTill', "useRawData"),
    init: function init() {
      this._super.apply(this, arguments);
      this.determineGraphDates();
      this.loadStatistics();
      this.determinePollResult();
    },
    didInsertElement: function didInsertElement() {},

    determineGraphDates: function determineGraphDates() {
      var start = (0, _moment.default)(this.get("appData.startDate"));
      var end = (0, _moment.default)(this.get("appData.endDate"));
      var today = (0, _moment.default)();

      var diffYears = end.diff(start, 'years', true);
      if (diffYears >= 1) {
        if (today.isBefore(end)) {
          end = today;
        }
      }
      diffYears = end.diff(start, 'years', true);
      if (diffYears >= 1) {
        start = (0, _moment.default)(end).add(-1, 'years');
      }

      start.set({ "hour": 0, "minute": 0, "second": 0, "millisecond": 0 });
      end.set({ "hour": 23, "minute": 59, "second": 59, "millisecond": 999 });

      this.set("statsFrom", start.toDate());
      this.set("statsTill", end.toDate());
    },
    loadGraph: function loadGraph() {
      var ctx = document.getElementById("statistics-graph1").getContext("2d");

      var labels = this.getGraphDataLabels();
      var data = {
        labels: labels.labels,
        datasets: [{
          label: this.get('i18n').t('views'),
          backgroundColor: "rgba(220,220,220,0.5)",
          borderColor: "rgba(220,220,220,0.8)",
          pointRadius: 4,
          pointBackgroundColor: "rgba(220,220,220,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          data: this.getGraphData(this.get('statistic.viewsPerDay'), labels.dates)
        }, {
          label: this.get('i18n').t('participations.label'),
          backgroundColor: "rgba(151,187,205,0.5)",
          borderColor: "rgba(151,187,205,0.8)",
          pointRadius: 4,
          pointBackgroundColor: "rgba(151,187,205,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(151,187,205,1)",
          data: this.getGraphData(this.get('statistic.participationsPerDay'), labels.dates)
        }]
      };
      while (data.labels.length < 2) {
        data.labels.push("...");
      }
      var options = {
        plugins: {
          // Change options for ALL labels of THIS CHART
          datalabels: {
            display: false
          }
        },
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            ticks: { min: 0 },
            beginAtZero: true,
            display: true
          }]
        }
      };
      //ctx.canvas.width  = document.getElementById("statistics-graph1").parentElement.offsetWidth;
      if (!this.get("chart")) {
        ctx.canvas.width = document.getElementById("statistics-graph1").parentElement.offsetWidth;
        var chart = new window.Chart(ctx, {
          type: 'line',
          data: data,
          options: options,
          legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><span style=\"background-color:<%=datasets[i].strokeColor%>\"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
        });
        this.set("chart", chart);
      } else {
        this.get("chart").data = data;
        this.get("chart").update();
      }
    },

    chart: null,

    getGraphDataLabels: function getGraphDataLabels() {
      // determine start and end date by first and last view
      var startDate = (0, _moment.default)(this.get('statistic.from'));
      var endDate = (0, _moment.default)(this.get('statistic.till'));

      if (startDate.isSame(endDate)) {
        startDate = startDate.subtract(1, 'days');
      }
      //endDate = endDate.add(1, 'minute');
      var graphdata = {
        labels: [],
        dates: []
      };
      while (startDate.isBefore(endDate)) {
        graphdata.labels.push(startDate.format('DD.MM.'));
        graphdata.dates.push(new Date(startDate.toDate().getTime()));
        startDate = startDate.add(1, 'days');
      }
      return graphdata;
    },
    getGraphData: function getGraphData(objects, dates) {

      var data = [];
      dates.forEach(function (date) {
        var val = 0;
        objects.forEach(function (obj) {
          if ((0, _moment.default)(date).isSame(new Date(obj.day), 'day')) {
            val = obj.total;
          }
        });
        data.push(val);
      });

      return data;
    },
    determinePollResult: function determinePollResult() {
      var _this4 = this;

      if (this.get('appData.appType') === 'poll') {
        this.get('api').request('/statistics/answerPercentage', { appId: this.get('appData.id') }).then(function (data) {
          var questionResultsChartData = [];
          data.forEach(function (values, index) {
            questionResultsChartData.push(_this4.getPollChartData(index, values));
          });
          _this4.set('questionResultsChartData', questionResultsChartData);
        });
      }
    },
    getPollChartData: function getPollChartData(questionIndex, values) {
      var defaultBackgroundColor = 'rgba(0, 0, 0, 0.4)';
      defaultBackgroundColor = 'rgba(75, 192, 192, 0.2)';
      var defaultBorderColor = 'rgba(0, 0, 0, 1)';
      defaultBorderColor = 'rgba(75, 192, 192, 1)';
      var valueLabel = '%';

      var valuesPercent = [];
      var labels = [];
      var labelAlign = [];
      var backgroundColor = [];
      var borderColor = [];

      var totalValues = 0;
      values.forEach(function (v) {
        totalValues += v;
      });

      // calculate % values
      var biggestValue = 0;
      values.forEach(function (v) {
        var val = totalValues === 0 ? 0 : Math.round(100 / totalValues * v);
        valuesPercent.push(val);
        if (val > biggestValue) {
          biggestValue = val;
        }
      });

      // label alignment
      var alignLeftVal = biggestValue * 0.20;
      valuesPercent.forEach(function (v) {
        labelAlign.push(v < alignLeftVal ? 'end' : 'start');
      });

      var question = this.get("appData.questions")[questionIndex];
      question.answers.forEach(function (a) {
        labels.push(a.label); // TODO: i18n for chart labels
        backgroundColor.push(defaultBackgroundColor);
        borderColor.push(defaultBorderColor);
      });

      var data = {
        hc2: { title: question.question },
        labels: labels,
        datasets: [{
          label: valueLabel,
          data: valuesPercent,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
          datalabels: {
            anchor: 'end',
            align: labelAlign,
            display: true,
            clip: true,
            clamp: true,
            formatter: function formatter(value) {
              return value + valueLabel;
            }
          }
        }]
      };
      return data;
    }
  });
});