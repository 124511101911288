define("hc2-ui/components/apps/pack-your-bag/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/helpers/has-html-content", "hc2-ui/lib/constants/global-app-translations"], function (exports, _init, _hasHtmlContent, _globalAppTranslations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: { availableBackgrounds: [] },
    selectedItem: 0,
    appStates: {
      showWelcomePage: false,
      selectedPage: 0
    },
    editableLabels: (0, _globalAppTranslations.getEditableLabels)("packYourBag"),

    calendar: Ember.computed.alias('appData'),
    availableBackgrounds: Ember.computed.alias('editorResources.availableBackgrounds'),

    showHeaderContentEditor: Ember.computed("appData.headerContent", function () {
      return (0, _hasHtmlContent.hasHtmlContent)(this.get("appData.headerContent"));
    }),

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      if (!display) {
        this.set("appStates.selectedPage", 0);
      }
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },
    init: function init() {
      this._super();
      var url = this.get("calendar.backgroundImageUri");
      if (!this.get('availableBackgrounds')) {
        this.set('availableBackgrounds', []);
      }
      if (url) {
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
      }
    },


    didRender: function didRender() {
      _init.default.material(this.elementId);
    },

    save: "save",
    actions: {
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
      },
      create: function create() {
        this.sendAction('save', this.get('appData'));
      },
      setHeaderImageFromUpload: function setHeaderImageFromUpload(url) {
        this.set('appData.headerImageUri', url);
      },
      setBackgroundFromUpload: function setBackgroundFromUpload(url) {
        if (!this.get('availableBackgrounds')) {
          this.set('availableBackgrounds', []);
        }
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
        this.set('calendar.backgroundImageUri', url);
      },
      setBackgroundPortraitFromUpload: function setBackgroundPortraitFromUpload(url) {
        if (!this.get('availableBackgrounds')) {
          this.set('availableBackgrounds', []);
        }
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
        this.set('calendar.backgroundImagePortraitUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      },
      addItem: function addItem() {
        this.get('appData.questions').pushObject({ answers: [] });
        this.set("selectedItem", this.get('appData.questions.length') - 1);
      },
      selectItem: function selectItem(index) {
        this.set("selectedItem", index);
      },
      removeItem: function removeItem(questionIndex) {
        this.get('appData.questions').removeAt(questionIndex);
        this.set("selectedItem", 0);
      },
      setItemImageFromUpload: function setItemImageFromUpload(url, item) {
        Ember.set(item, 'imageUri', url);
      },
      selectParticipationPage: function selectParticipationPage() {
        this.switchWelcomePageIfActive(false);
        this.set("appStates.selectedPage", 1);
      }
    },
    setupModel: function () {

      this.set("appStates.selectedPage", 0);

      if (!this.get('appData.questions')) {
        this.set('appData.questions', Ember.A([]));
      }
      if (!this.get('appData.contactData')) {
        this.set('appData.contactData', {
          salutationMandetory: true,
          nameMandetory: true,
          firstName: true,
          firstNameMandetory: true,
          lastName: true,
          lastNameMandetory: true,
          email: true,
          emailMandetory: true,
          extraFromElements: []
        });
      }
    }.on("init"),
    didInsertElement: function didInsertElement() {
      //$(".app-editor-settings form > .nav li:nth-child(4) a").click();
    }
  });
});