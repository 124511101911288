define('hc2-ui/services/i18n', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert;
  exports.default = Ember.Service.extend({
    appLocale: "de",
    _appTranslations: Ember.computed.readOnly('app.translations'),
    intl: Ember.inject.service(),
    config: Ember.inject.service(),
    locales: Ember.computed.alias("intl.locales"),
    locale: Ember.computed("intl.primaryLocale", {
      get: function get() {
        return this.get("intl.primaryLocale");
      },
      set: function set(key, value) {
        this.setLocale(value);
        return value;
      }
    }),
    getUserLocale: function getUserLocale() {
      return navigator.language || navigator.userLanguage || this.get('locale');
    },
    getUserLanguage: function getUserLanguage() {
      return this.getUserLocale().toLowerCase().substr(0, 2);
    },

    initLocale: function initLocale() {
      var localeParam = this.get("config").getUrlParameters()["lang"];
      if (localeParam) {
        this.initWithLocale(localeParam);
      } else {
        this.initWithBrowserLocale();
      }
    },
    initWithBrowserLocale: function initWithBrowserLocale() {
      var locale = this.getUserLocale();
      this.initWithLocale(locale);
    },
    initWithLocale: function initWithLocale(locale) {
      var language = locale.substring(0, 2);

      if (this.get('locales').indexOf(locale) > -1) {
        this.setLocale(locale);
      } else if (this.get('locales').indexOf(language) > -1) {
        this.setLocale(language);
      } else {
        this.setLocale("de");
        locale = "de";
      }
      _moment.default.locale(locale);
    },
    setLocale: function setLocale(locale) {
      this.get("intl").set("locale", locale);
    },
    t: function t() {
      var _get;

      return (_get = this.get("intl")).t.apply(_get, arguments);
    },


    tu: function tu(key, fallback) {
      var appData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var appLocale = this.get("appLocale");

      // check app translations
      var translations = this.get("_appTranslations");
      if (appData) {
        translations = appData.get("translations");
      }
      if (translations) {
        var translationsKey = appLocale + "_appdata_" + key.replace(/\./g, "_");
        if (translations && translations[translationsKey]) {
          return translations[translationsKey];
        }
      }
      return fallback;
    },

    ta: function ta(key) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var translations = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

      return this.taWithData(key, data, translations);
    },
    taWithData: function taWithData(key, data, translations) {
      var _get2;

      var appLocale = this.get('appLocale');
      assert("I18n: Cannot translate when appLocale is null", appLocale);

      // try app.translations
      if (!translations) {
        translations = this.get("app.translations");
      }
      if (translations) {
        var translationsKey = this.get("appLocale") + "_" + key.replace(/\./g, "_");
        if (translations && translations[translationsKey]) {
          try {
            return this.get("intl").formatMessage(translations[translationsKey], data);
          } catch (e) {
            return translations[translationsKey];
          }
          return translations[translationsKey];
        }
      }

      // try translation by appLocale
      if (this.get('locale') !== appLocale) {
        data.locale = appLocale;
      }

      // translation by general locale
      return (_get2 = this.get("intl")).t.apply(_get2, arguments);
    },

    tl: function tl(locale, key) {
      var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};


      // try translation by appLocale
      if (this.get('locale') !== locale) {
        data.locale = locale;
      }

      // translation by general locale
      try {
        return this.get("intl").t(key, data);
      } catch (e) {
        return this.get("intl").lookup(key, locale);
      }
    }
  });
});