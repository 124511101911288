define("hc2-ui/components/apps/vote-button", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    i18n: Ember.inject.service(),

    classes: null,

    participation: null,

    showRecaptcha: false,
    captchaResponse: null,
    email: null,
    ratedResetter: Ember.observer('participation', function () {
      this.set('rated', false);
    }),
    rated: false,

    votedPopUpInitialized: false,
    activatePopUpInitialized: false,

    vote: "vote",

    actions: {
      vote: function vote() /*participation*/{
        this.voteCycle();
      },
      captchaFulfilled: function captchaFulfilled(response) {
        this.set("captchaResponse", response);
        var captchaInContactForm = this.get('participation.app.votesNeedEmailActivation') && this.get('participation.app.votesContactData') && this.get("participation.app.participationsNeedCaptcha");
        if (!captchaInContactForm) {
          this.voteCycle();
        }
      }
    },
    voteCycle: function voteCycle() {
      if (!this.get('rated')) {
        var captchaInContactForm = this.get('participation.app.votesNeedEmailActivation') && this.get('participation.app.votesContactData') && this.get("participation.app.participationsNeedCaptcha");

        if (this.get('participation.app.votesNeedCaptcha') && !this.get('captchaResponse') && !captchaInContactForm) {
          this.set('showRecaptcha', true);
        } else if (this.get('participation.app.votesNeedEmailActivation') && this.get('participation.app.votesContactData')) {
          this.showContactDataForm();

          if (this.get("contactDataFormOpened") && this.validataContactFormData()) {
            var o = {};
            var a = window.$('#' + this.get('elementId') + ' form').serializeArray();
            window.$.each(a, function () {
              if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                  o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
              } else {
                o[this.name] = this.value || '';
              }
            });

            o.locale = this.get("i18n.appLocale");
            o.source = this.get("session.source");
            var email = o.email || this.get("email");
            this.saveVote(this.get("captchaResponse"), email, o);
          }

          this.set("contactDataFormOpened", true);
        } else if (this.get('participation.app.votesNeedEmailActivation')) {
          _init.default.material();
          this.showEmailForm();
          if (this.get("emailFormOpened") && this.$('.vote-email-popup-body form').parsley().validate() && this.get("conditionsAccepted")) {
            this.saveVote(this.get("captchaResponse"), this.get("email"));
          }
          if (this.get("emailFormOpened")) {
            this.set("showNoConditionsMessage", true);
          }
          this.set("emailFormOpened", true);
        } else {
          this.saveVote(this.get("captchaResponse"), this.get("email"));
        }
      }
    },
    showEmailForm: function showEmailForm() {
      window.$('#' + this.elementId + '-email-popup').modal();
    },
    showContactDataForm: function showContactDataForm() {
      window.$('#' + this.elementId + '-contact-form-popup').modal();
    },
    validataContactFormData: function validataContactFormData() {
      // check required fields on current page
      var requiredInputs = window.$('#' + this.elementId + '-contact-form-popup input[required]');
      var requiredPatternInputs = window.$('#' + this.elementId + '-contact-form-popup input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('#' + this.elementId + '-contact-form-popup form input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('#' + this.elementId + '-contact-form-popup form input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      var multiCheckboxes = window.$('#' + this.elementId + '-contact-form-popup .multi-checkbox.mandetory-checkbox');
      if (multiCheckboxes.length == 1) {
        if (window.$('input:checked', multiCheckboxes).length == 0) {
          var fg = multiCheckboxes.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var _msg = this.get('i18n').ta('mandetory.field.hint.radio');
          msgBox.find('span').text(_msg);
          msgBox.show().delay(5000).fadeOut();
        }
      }
      return allRequiredSet;
    },

    saveVote: function saveVote(captchaResponse, email, formData) {
      var that = this;
      this.get('store').createRecord("vote", {
        participation: this.get('participation'),
        reCaptchaResponse: captchaResponse,
        email: email,
        formData: formData,
        rating: 1,
        domain: window.location.hostname,
        locale: this.get("i18n.appLocale")
      }).save().then(function (vote) {
        if (vote.get('active')) {
          that.set('participation.rating', that.get('participation.rating') + vote.get('rating'));
          window.$('#' + that.elementId + '-voted-popup').modal();
          that.set('votedPopUpInitialized', true);
        } else {
          window.$('#' + that.elementId + '-activate-popup').modal();
          that.set('activatePopUpInitialized', true);
        }
        window.$('#' + that.elementId + '-contact-form-popup').modal('hide');
      }, function (vote) {
        if (vote.errors) {
          if (vote.errors[0].detail === "participation.error.invalidEmail") {
            alert(that.get("i18n").ta("participation.error.invalidEmail.sweepstake.participation"));
            that.set('rated', false);
            that.set('captchaResponse', null);
            that.set('email', null);
            return;
          }
          if (vote.errors[0].field === 'email') {
            alert(that.get("i18n").ta("vote.email.valid.required"));
            that.set('rated', false);
          } else if (vote.errors[0].detail === "reCaptcha.error") {
            that.set("captchaReloadTriggerDate", Date.now());
            alert(that.get("i18n").ta("reCaptcha.error.sweepstake.participation"));
            that.set('rated', false);
            that.set('captchaResponse', null);
            that.set('email', null);
            return;
          } else if (vote.errors[0].detail === "already.votedMax") {
            alert(that.get("i18n").ta("vote.already.votedMax"));
            return;
          } else {
            alert(that.get("i18n").ta("vote.already.voted"));
            return;
          }
        } else {
          alert(that.get("i18n").ta("vote.error"));
          return;
        }
      });

      window.$('#' + this.elementId + '-email-popup').modal('hide');
      this.set('showRecaptcha', false);
      this.set('rated', true);
    }
  });
});