define('hc2-ui/helpers/is-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    if (params[0] == null) {
      return false;
    }
    return !isNaN(params[0]);
  });
});