define("hc2-ui/helpers/replace-placeholders", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var text = params[0];
    var placeholderData = params[1];
    if (!text || !placeholderData) {
      return text;
    }
    var placeholders = text.match(/\{\w*\}/g);
    if (placeholders) {
      placeholders.forEach(function (p) {
        var pp = p.substring(1, p.length - 1);
        var prefixes = ["", "formData."];
        for (var i = 0; i < prefixes.length; i++) {
          var prefix = prefixes[i];
          var value = void 0;
          if (placeholderData.get) {
            value = placeholderData.get(prefix + pp);
          } else {
            value = placeholderData[prefix + pp];
          }
          if (value) {
            text = text.replaceAll(p, value);
          }
        }
      });
    }
    return text;
  });
});