define("hc2-ui/components/ga-optout", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tracking: Ember.inject.service(),
    i18n: Ember.inject.service(),
    disabled: Ember.computed.not("tracking.trackingEnabled"),
    actions: {
      optout: function optout() {
        this.get("tracking").trackingOptOut();
        this.set("disabled", true);
      },
      optin: function optin() {
        this.get("tracking").trackingOptIn();
        this.set("disabled", false);
      }
    }
  });
});