define('hc2-ui/models/app-integration', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    mailchimp: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),
    emarsys: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),
    episerver: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),
    inxmail: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),
    facebookPixel: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),
    app: _emberData.default.belongsTo('apps/app', { polymorphic: true, async: true })
  });
});