define("hc2-ui/components/apps/memory/app-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    api: Ember.inject.service(),
    config: Ember.inject.service(),
    tracking: Ember.inject.service(),
    session: Ember.inject.service(),
    embed: Ember.inject.service(),
    captchaReloadTriggerDate: Date.now(),

    duration: 0,

    appData: {},
    editMode: false,
    selectedPage: Ember.computed.alias("appStates.selectedPage"),
    showBoard: Ember.computed.equal("selectedPage", 0),
    showParticipationForm: Ember.computed("selectedPage", "appData.noForm", function () {
      return this.get("selectedPage") == 1 && !this.get("appData.noForm");
    }),
    showThanks: Ember.computed("selectedPage", "showParticipationForm", function () {
      return this.get("selectedPage") > 0 && !this.get("showParticipationForm");
    }),
    appStates: {
      selectedPage: 0,
      showWelcomePage: false
    },
    localStoreDataKey: Ember.computed("appData.id", function () {
      return this.get("appData.owner.id") + "-storedFormData";
    }),
    loadedFromLocalStorage: false,
    loadFromLocalStorage: function loadFromLocalStorage() {
      var _this = this;

      if (this.get("appData.parentApp.id") && !this.get("loadedFromLocalStorage") && !this.get("appData.parentApp.dontSaveFormData")) {
        var retrievedObject = window.localStorage.getItem(this.get("localStoreDataKey"));
        if (retrievedObject) {
          var storedData = JSON.parse(retrievedObject);
          Object.keys(storedData).forEach(function (id) {
            var inputId = id;
            var inputType = "textfield";
            var mandetory = false;
            if (id.startsWith("extraFromElementLabel__")) {
              var extraFromElementLabel = id.split("__")[1];
              _this.get("appData.contactData.extraFromElements").forEach(function (it, index) {
                if (it.label === extraFromElementLabel) {
                  inputId = "extraFromElement-" + index;
                  inputType = it.type;
                  mandetory = it.mandetory;
                }
              });
            }
            try {
              var input = _this.$('form [name=' + inputId + ']');
              if (input.length > 1) {
                // assume radio
                inputType = 'radio';
              }

              if (inputType === 'textfield') {
                input.val(storedData[id]);
                input.removeClass("empty");
              } else if (inputType === 'radio') {
                _this.$('input:radio[name="' + inputId + '"]').filter('[value="' + storedData[id] + '"]').attr('checked', true);
              } else if (inputType === 'select') {
                _this.set(inputId, storedData[id]);
              } else if (inputType === 'checkbox') {
                if (!mandetory) {
                  if (storedData[id] === 'on') {
                    input.prop('checked', true);
                  }
                }
              }
              if (id === 'lotCount') {
                _this.set("lotCount", storedData[id]);
              }
            } catch (e) {}
          });
        }
        this.set("loadedFromLocalStorage", true);
      }
    },
    saveToLoacalStorage: function saveToLoacalStorage(o) {
      var _this2 = this;

      if (this.get("appData.parentApp.id")) {
        var r = window.localStorage.getItem(this.get("localStoreDataKey"));
        var e = r ? JSON.parse(r) : {};
        Object.keys(o).forEach(function (id) {
          if (!id.startsWith("answer")) {
            var key = id;
            if (id.startsWith("extraFromElement")) {
              var extraFromElementIndex = id.split("-")[1];
              var extraFromElement = _this2.get("appData.contactData.extraFromElements")[extraFromElementIndex];
              if (extraFromElement) {
                key = "extraFromElementLabel__" + extraFromElement.label;
              }
            }
            e[key] = o[id];
          }
        });
        window.localStorage.setItem(this.get("localStoreDataKey"), JSON.stringify(e));
      }
    },

    participation: null,
    active: Ember.computed('appData.startDate', 'appData.endDate', function () {
      var now = new Date();
      return now > this.get('appData.startDate') && now < this.get('appData.endDate');
    }),

    showTabs: Ember.computed("appData.descriptionHeader", "appData.additionalInformationHeader", function () {
      return this.get("appData.descriptionHeader") || this.get("appData.additionalInformationHeader");
    }),
    init: function init() {
      this._super();
      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));
      if (!this.get('editMode') && !this.get("appData.isNew")) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }
      this.set("appStates.selectedTab", this.get("appData.descriptionHeader") ? "description" : "participate");
    },
    tabChange: Ember.observer("appStates.selectedTab", function () {
      var _this3 = this;

      Ember.run.later(function () {
        _this3.set("tab", _this3.get("appStates.selectedTab"));
      });
    }).on("init"),
    stepChange: Ember.observer("selectedPage", function () {
      var _this4 = this;

      Ember.run.later(function () {
        _this4.set("step", _this4.get("selectedPage") === _this4.get("appData.questions.length") + 1 ? "done" : _this4.get("selectedPage") + 1);
      });
    }).on("init"),
    didRender: function didRender() {
      this.get("tracking").initLinkTracking();
      _init.default.material(this.elementId);
      this.loadFromLocalStorage();

      // Handler to check for correct answer on radio questions and show hint+
      window.$('#' + this.get('elementId') + ' input[data-correct]').off('click');
      var that = this;
      window.$('#' + this.get('elementId') + ' input[data-correct]').click(function (event) {
        var isCorrect = window.$(event.currentTarget).data().correct;
        var inputName = window.$(event.currentTarget).attr('name');
        //var answer = window.$('input[name=answer-1]:checked').val();

        window.$('#' + inputName + "-hint").html('<span class="label ' + (isCorrect ? 'label-success' : 'label-danger') + '">' + that.get('i18n').ta('question.result.' + isCorrect) + '</span>');
      });
    },

    actions: {
      setSelectedTab: function setSelectedTab(tabname) {
        this.set("appStates.selectedTab", tabname);
      },
      selectPage: function selectPage(page) {
        this.set('selectedPage', page);
      },
      selectPrevPage: function selectPrevPage() {
        this.decrementProperty('selectedPage');
      },
      selectNextPage: function selectNextPage() {

        if (this.get('showBoard') && this.get('appData.noForm')) {
          this.send("submitForm");
        } else {
          // increment if required fields set
          if (this.isActiveTabValid()) {
            this.incrementProperty('selectedPage');
          }
        }
      },
      submitForm: function submitForm() {
        var _this5 = this;

        if (this.isActiveTabValid() && !this.get("savingParticipation")) {
          this.set("savingParticipation", true);
          var o = {};
          var a = window.$('#' + this.get('elementId') + ' form').serializeArray();
          window.$.each(a, function () {
            if (o[this.name] !== undefined) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });

          o.locale = this.get("i18n.appLocale");
          o.source = this.get("session.source");
          o.duration = this.get("duration");
          if (window.hc2_ad && window.hc2_ad.form) Ember.$.extend(o, window.hc2_ad.form);

          var participation = this.get('store').createRecord("participation", {
            app: this.get('appData'), formData: o, domain: window.location.hostname
          });
          this.set('participation', participation);
          participation.save().then(function (participation) {
            if (_this5.get("appData.lotSaleActive")) {
              return _this5.get("api").request("/paypal/approvalUrlForParticipation/" + participation.get("id")).then(function (response) {
                window.location.href = response.approvalUrl;
                return true;
              }).fail(function () {
                _this5.set("savingParticipation", false);
              });
            } else {
              _this5.incrementProperty('selectedPage');
              _this5.saveToLoacalStorage(o);
            }
            _this5.get("tracking").sendLeadEvent(_this5.get("appData.title"));
          }).catch(function () {
            _this5.set("savingParticipation", false);_this5.set("captchaReloadTriggerDate", Date.now());
          }).finally(function () {
            if (!_this5.get("appData.lotSaleActive")) {
              _this5.set("savingParticipation", false);
            }
          });
        }
      },
      hideWelcomePage: function hideWelcomePage() {
        this.get("embed").notifyStepChange();
        this.set("appStates.showWelcomePage", false);
      },
      onGameDone: function onGameDone(duration) {
        this.set("duration", duration);
        if (this.get("participation.id") && !this.get("appData.noForm") && !this.get("appData.allowMultipleParticipations")) {
          this.set("selectedPage", 2);
        } else {
          this.send("selectNextPage", true);
        }
      },
      reset: function reset() {
        this.set("selectedPage", 0);
      }
    },
    isActiveTabValid: function isActiveTabValid() {
      // check required fields on current page
      var requiredInputs = window.$('#tab-ss-participate input[required]');
      var requiredPatternInputs = window.$('#tab-ss-participate input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      var multiCheckboxes = window.$('#tab-ss-participate .tab-pane.active .multi-checkbox.mandetory-checkbox');
      if (multiCheckboxes.length == 1) {
        if (window.$('input:checked', multiCheckboxes).length == 0) {
          var fg = multiCheckboxes.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var _msg = this.get('i18n').ta('mandetory.field.hint.radio');
          msgBox.find('span').text(_msg);
          msgBox.show().delay(5000).fadeOut();
        }
      }
      return allRequiredSet;
    },

    didInsertElement: function didInsertElement() {
      _init.default.material(this.elementId);

      // show event popup
      if (this.get('config').setting("event") === "paymentApproval") {
        this.showThanksPane();
      }
    },
    showThanksPane: function showThanksPane() {
      this.set("selectedPage", this.get("appData.questions.length") + 1);
    }
  });
});