define('hc2-ui/models/user-integration', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    mailchimp: _emberData.default.attr(),
    paypal: _emberData.default.attr(),
    ga: _emberData.default.attr(),
    gHook: _emberData.default.attr(),
    emarsys: _emberData.default.attr(),
    episerver: _emberData.default.attr(),
    inxmail: _emberData.default.attr(),
    facebookPixel: _emberData.default.attr(),
    available: Ember.computed("facebookPixel.active", "mailchimp.active", "emarsys.active", "inxmail.active", function () {
      return this.get("mailchimp.active") || this.get("emarsys.active") || this.get("inxmail.active") || this.get("facebookPixel.active") || this.get("episerver.active");
    })
  });
});