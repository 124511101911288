define('hc2-ui/models/apps/scratch-card', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _app.default.extend({
        noForm: _emberData.default.attr('boolean'),
        prizes: _emberData.default.attr(),
        contactData: _emberData.default.attr(),
        contactFormText: _emberData.default.attr('string'),
        headerImageUri: _emberData.default.attr("string"),
        thanksText: _emberData.default.attr("string"),
        allowMultipleParticipations: _emberData.default.attr('boolean', { defaultValue: false }),
        scratchCardImageUrl: _emberData.default.attr("string"),
        scratchCardBackgroundImageUrl: _emberData.default.attr("string"),
        headerContent: _emberData.default.attr('string'),
        resultMails: _emberData.default.attr('boolean'),
        percentForm: _emberData.default.attr("number", { defaultValue: 5 }),
        percentDone: _emberData.default.attr("number", { defaultValue: 70 })
    });
});