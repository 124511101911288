define("hc2-ui/components/apps/christmas-calendar/editor-form", ["exports", "hc2-ui/lib/make/init", "hc2-ui/lib/constants/global-app-translations"], function (exports, _init, _globalAppTranslations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appData: {},
    editorResources: {},
    appStates: {
      showWelcomePage: false
    },
    editableLabels: (0, _globalAppTranslations.getEditableLabels)("christmasCalendar"),

    calendar: Ember.computed.alias('appData'),
    availableBackgrounds: Ember.computed.alias('editorResources.availableBackgrounds'),

    observeWelcomePage: function () {
      this.switchWelcomePageIfActive(true);
    }.observes("appData.welcomePage"),
    switchWelcomePageIfActive: function switchWelcomePageIfActive(display) {
      this.set("appStates.showWelcomePage", this.get("appData.welcomePage") && display);
    },
    init: function init() {
      this._super();
      var url = this.get("calendar.backgroundImageUri");
      if (url) {
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
      }
    },


    didRender: function didRender() {
      _init.default.material(this.elementId);
    },

    save: "save",
    actions: {
      toggleWelcomePageIfActive: function toggleWelcomePageIfActive(display) {
        this.switchWelcomePageIfActive(display);
      },
      create: function create() {
        this.sendAction('save', this.get('calendar'));
      },
      setBackgroundFromUpload: function setBackgroundFromUpload(url) {
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
        this.set('calendar.backgroundImageUri', url);
      },
      setBackgroundPortraitFromUpload: function setBackgroundPortraitFromUpload(url) {
        this.get('availableBackgrounds').push({ id: url, text: url.substring(url.lastIndexOf('/') + 1) });
        this.set('calendar.backgroundImagePortraitUri', url);
      },
      setLogoFromUpload: function setLogoFromUpload(url) {
        this.set('calendar.logoUri', url);
      },
      handleError: function handleError(err) {
        alert("Error:\n" + err);
      }
    }
  });
});