define("hc2-ui/components/apps/poll/app-view", ["exports", "hc2-ui/lib/make/init"], function (exports, _init) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    api: Ember.inject.service(),
    config: Ember.inject.service(),
    tracking: Ember.inject.service(),
    session: Ember.inject.service(),
    embed: Ember.inject.service(),

    appData: {},
    editMode: false,
    selectedPage: 0,
    currentQuestion: Ember.computed("selectedPage", "appData.questions.[]", function () {
      return this.get("appData.questions").objectAt(this.get("selectedPage"));
    }),

    appStates: {
      selectedQuestion: 0,
      showWelcomePage: false
    },
    participation: null,
    active: Ember.computed('appData.startDate', 'appData.endDate', function () {
      var now = new Date();
      return now > this.get('appData.startDate') && now < this.get('appData.endDate');
    }),
    changingSelectedQuestion: Ember.observer('appStates.selectedQuestion', function () {
      this.set("selectedPage", this.get("appStates.selectedQuestion"));
    }),

    changingSelected: Ember.observer('appData.questions.[]', function () {
      this.rerender();
    }),

    showTabs: Ember.computed("appData.descriptionHeader", "appData.additionalInformationHeader", function () {
      return this.get("appData.descriptionHeader") || this.get("appData.additionalInformationHeader");
    }),
    progress: Ember.computed("appData.questions.length", "selectedPage", "appData.noForm", function () {
      var steps = this.get("appData.questions.length");
      if (!this.get("appData.noForm")) {
        steps++;
      }
      var progress = 100 / steps * (this.get("selectedPage") + 1);
      return progress;
    }),
    init: function init() {
      this._super();
      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));
      if (!this.get('editMode') && !this.get("appData.isNew")) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }
      this.set("appStates.selectedTab", this.get("appData.descriptionHeader") ? "description" : "participate");
    },
    tabChange: Ember.observer("appStates.selectedTab", function () {
      var _this = this;

      Ember.run.later(function () {
        _this.set("tab", _this.get("appStates.selectedTab"));
      });
    }).on("init"),
    stepChange: Ember.observer("selectedPage", function () {
      var _this2 = this;

      Ember.run.later(function () {
        var plusForLastStep = _this2.get("appData.noForm") ? 0 : 1;
        _this2.set("step", _this2.get("selectedPage") === _this2.get("appData.questions.length") + plusForLastStep ? "done" : _this2.get("selectedPage") + 1);
      });
    }).on("init"),
    didRender: function didRender() {
      this.get("tracking").initLinkTracking();
      _init.default.material(this.elementId);

      // Handler to check for correct answer on radio questions and show hint+
      window.$('#' + this.get('elementId') + ' input[data-correct]').off('click');
      var that = this;
      window.$('#' + this.get('elementId') + ' input[data-correct]').click(function (event) {
        var isCorrect = window.$(event.currentTarget).data().correct;
        var inputName = window.$(event.currentTarget).attr('name');
        //var answer = window.$('input[name=answer-1]:checked').val();

        window.$('#' + inputName + "-hint").html('<span class="label ' + (isCorrect ? 'label-success' : 'label-danger') + '">' + that.get('i18n').ta('question.result.' + isCorrect) + '</span>');
      });
    },
    stripTags: function stripTags(html) {
      if (!html) {
        return "";
      }
      var tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      var text = tmp.textContent || tmp.innerText || "";
      return text.replace(/\s/g, '');
    },


    actions: {
      setSelectedTab: function setSelectedTab(tabname) {
        this.set("appStates.selectedTab", tabname);
      },
      toggleCollapse: function toggleCollapse(event) {
        Ember.$(event.currentTarget).toggleClass("multiline-ellipsis-2");
        //return false;
      },

      selectPage: function selectPage(page) {
        this.set('selectedPage', page);
      },
      changedAnswer: function changedAnswer(answer) {
        this.set("currentAnswer", answer);
      },

      selectNextPage: function selectNextPage() {

        // increment if required fields set
        if (this.isActiveTabValid()) {

          if (this.get("selectedPage") < this.get("appData.questions.length") && !this.get("answerCommited")) {
            var showResults = this.get("appData.showResultsAfterEachQuestion");
            if (showResults) {
              this.determineResultForCurrentQuestion();
              this.set("answerCommited", true);
            } else {
              if (this.get("appData.noForm") && this.get("selectedPage") === this.get("appData.questions.length") - 1) {
                this.set("submittingResult", true);
                this.send("submitForm");
              } else {
                this.incrementProperty('selectedPage');
              }
            }
          } else {
            if (this.get("appData.noForm") && this.get("selectedPage") === this.get("appData.questions.length") - 1) {
              this.set("submittingResult", true);
              this.send("submitForm");
            } else if (this.get("selectedPage") === this.get("appData.questions.length")) {
              this.set("submittingResult", true);
              this.send("submitForm");
            } else {
              this.incrementProperty('selectedPage');
            }
            this.set("answerCommited", false);
          }
        }
      },
      submitForm: function submitForm() {
        var _this3 = this;

        if (this.isActiveTabValid() && !this.get("savingParticipation")) {
          this.set("savingParticipation", true);
          var o = {};
          var f = window.$('#' + this.get('elementId') + ' form');
          var a = f.serializeArray();
          window.$.each(a, function () {
            if (o[this.name] !== undefined) {
              if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
              }
              o[this.name].push(this.value || '');
            } else {
              o[this.name] = this.value || '';
            }
          });
          f.find(':disabled[name]:checked', this).each(function () {
            o[this.name] = this.value || '';
          });

          o.locale = this.get("i18n.appLocale");
          o.source = this.get("session.source");
          if (window.hc2_ad && window.hc2_ad.form) {
            Ember.$.extend(o, window.hc2_ad.form);
          }

          var that = this;
          var participation = this.get('store').createRecord("participation", {
            app: this.get('appData'), formData: o, domain: window.location.hostname
          });
          this.set('participation', participation);
          participation.save().then(function () /*participation*/{
            that.determineResult();
            that.set('selectedPage', that.get('appData.questions.length') + (that.get("appData.noForm") ? 0 : 1));
            that.get("tracking").sendLeadEvent(that.get("appData.title"));
          }).finally(function () {
            that.set("submittingResult", false);
            _this3.set("savingParticipation", false);
          });
        }
      },
      hideWelcomePage: function hideWelcomePage() {
        this.get("embed").notifyStepChange();
        this.set("appStates.showWelcomePage", false);
      }
    },
    determineResult: function determineResult() {
      var _this4 = this;

      if (this.get("appData.showResultsAtTheEnd")) {
        this.get('api').request('/statistics/answerPercentage', { appId: this.get('appData.id') }).then(function (data) {
          _this4.set('questionResultsData', data);
        });
      }
    },

    questionResultsChartData: Ember.computed("questionResultsData", "i18n.appLocale", "i18n.locale", function () {
      var _this5 = this;

      var questionResultsChartData = [];
      var data = this.get("questionResultsData");
      if (data) {
        data.forEach(function (values, index) {
          questionResultsChartData.push(_this5.getChartData(index, values));
        });
      }
      return questionResultsChartData;
    }),
    determineResultForCurrentQuestion: function determineResultForCurrentQuestion() {
      var _this6 = this;

      var questionIndex = this.get("selectedPage");

      var currentAnswer = window.$('#' + this.get('elementId') + ' .question-' + questionIndex + ' input:checked').val();

      this.get('api').request('/statistics/answerPercentage', { appId: this.get('appData.id'), questionId: questionIndex, currentAnswer: currentAnswer }).then(function (data) {
        _this6.set("currentQuestionResultData", [questionIndex, data[0]]);
      });
    },

    currentQuestionResultChartData: Ember.computed("currentQuestionResultData", "i18n.locale", "i18n.appLocale", function () {
      var d = this.get("currentQuestionResultData");
      if (!d) return [];
      var questionResultChartData = this.getChartData(d[0], d[1]);
      return questionResultChartData;
    }),
    isActiveTabValid: function isActiveTabValid() {
      // check required fields on current page
      var requiredInputs = window.$('#tab-ss-participate .tab-pane.active input[required]');
      var requiredPatternInputs = window.$('#tab-ss-participate .tab-pane.active input[data-required-pattern]');
      var inputsToCheck = window.$.merge(requiredInputs, requiredPatternInputs);
      var allRequiredSet = true;
      for (var i = 0; i < inputsToCheck.length; i++) {
        var type = requiredInputs[i].type;
        var val = null;
        var field = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]');
        if ('radio' === type || 'checkbox' === type) {
          val = window.$('#tab-ss-participate form input[name="' + requiredInputs[i].name + '"]:checked').val();
        } else {
          val = field.val();
        }

        var required = field.prop("required");
        var requiredPattern = field.attr("data-required-pattern");

        var requiredValid = required ? val : true;
        var patternValid = true;
        if (requiredPattern && val) {
          patternValid = val.match(requiredPattern);
        }

        if (!requiredValid || !patternValid) {
          var fg = field.closest('.form-group');
          fg.addClass('has-error');
          allRequiredSet = false;
          var msgBox = fg.find('.mandetoryFieldMessage');
          if (msgBox.length === 0) {
            msgBox = window.$('<div class="mandetoryFieldMessage"><span class="text-danger"></span></div>');
            fg.append(msgBox);
          }
          var msg = !requiredValid ? this.get('i18n').ta('mandetory.field.hint.' + type) : this.get('i18n').ta('mandetory.field.patternHint.' + type);
          msgBox.find('span').text(msg);
          msgBox.show().delay(5000).fadeOut();
        } else {
          field.closest('.form-group').removeClass('has-error');
        }
      }
      return allRequiredSet;
    },

    didInsertElement: function didInsertElement() {
      _init.default.material(this.elementId);
    },
    getChartData: function getChartData(questionIndex, values) {
      var _this7 = this;

      var defaultBackgroundColor = 'rgba(0, 0, 0, 0.4)';
      defaultBackgroundColor = 'rgba(75, 192, 192, 0.2)';
      var defaultBorderColor = 'rgba(0, 0, 0, 1)';
      defaultBorderColor = 'rgba(75, 192, 192, 1)';
      var valueLabel = '%';

      var valuesPercent = [];
      var labels = [];
      var labelAlign = [];
      var backgroundColor = [];
      var borderColor = [];

      var totalValues = 0;
      values.forEach(function (v) {
        totalValues += v;
      });

      // calculate % values
      var biggestValue = 0;
      values.forEach(function (v) {
        var val = totalValues === 0 ? 0 : Math.round(100 / totalValues * v);
        valuesPercent.push(val);
        if (val > biggestValue) {
          biggestValue = val;
        }
      });

      // label alignment
      var alignLeftVal = biggestValue * 0.20;
      valuesPercent.forEach(function (v) {
        labelAlign.push(v < alignLeftVal ? 'end' : 'start');
      });

      var question = this.get("appData.questions")[questionIndex];
      question.answers.forEach(function (a, i) {
        labels.push(_this7.get("i18n").tu('questions_' + questionIndex + '_answers_' + i + '_label', a.label, _this7.get("appData")));
        backgroundColor.push(defaultBackgroundColor);
        borderColor.push(defaultBorderColor);
      });

      var qLabel = this.get("i18n").tu('questions_' + questionIndex + '_question', question.question, this.get("appData"));

      var data = {
        hc2: { title: qLabel },
        labels: labels,
        datasets: [{
          label: valueLabel,
          data: valuesPercent,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
          datalabels: {
            anchor: 'end',
            align: labelAlign,
            display: true,
            clip: true,
            clamp: true,
            formatter: function formatter(value) {
              return value + valueLabel;
            }
          }
        }]
      };
      return data;
    }
  });
});