define('hc2-ui/helpers/str-sub', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var str = params[0];
    if (params[2] < 0) {
      str = str.substring(0, str.length + params[2]);
    }
    return str.substring(params[1]);
  });
});