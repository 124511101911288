define('hc2-ui/helpers/object-at', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.helper(function (params) {
        if (!params[0]) {
            return null;
        }
        return params[0].objectAt(params[1]);
    });
});