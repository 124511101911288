define('hc2-ui/components/controls/capt-check', ['exports', 'hc2-ui/config/environment', 'hc2-ui/lib/make/init'], function (exports, _environment, _init) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    apiUrl: _environment.default.captcheck.apiUrl,
    captchaFulfilled: 'captchaFulfilled',

    tracking: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      _init.default.material();
      this.loadChallenge();
    },

    init: function init() {
      this._super.apply(this, arguments);
    },

    reloadCaptchaOnError: function () {
      var _this = this;

      var errors = this.get("targetObject");
      console.log(errors);
      errors.get("errors").forEach(function (e) {
        if (e.message === "reCaptcha.error") {
          console.log(e);
          _this.loadChallenge();
        }
      });
    }.observes("targetObject.errors.length"),

    reloadCaptchaOnTrigger: function () {
      this.loadChallenge();
    }.observes("reloadTriggerDate"),

    loadChallenge: function loadChallenge() {
      var _this2 = this;

      Ember.$.getJSON(_environment.default.captcheck.apiUrl + "?action=new").then(function (response) {
        _this2.set("challenge", response);
      });
    },


    actions: {
      selectAnswer: function selectAnswer(answer, ev) {
        this.set("selectedAnswer", answer);
        try {
          this.sendAction("captchaFulfilled", answer + "@" + this.get("challenge.session"));
        } catch (e) {
          console.log("no action handler for captcha fulfilled");
        }
        ev.preventDefault();
      },
      showChallenge: function showChallenge() {
        this.set("showChallenge", !this.get("showChallenge"));
      },
      reloadChallenge: function reloadChallenge() {
        this.loadChallenge();
      }
    }
  });
});