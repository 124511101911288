define('hc2-ui/components/apps/calendar/app-view', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tracking: Ember.inject.service(),
    embed: Ember.inject.service(),
    pageTitleList: Ember.inject.service(),
    i18n: Ember.inject.service(),

    appData: {},
    calendar: Ember.computed.alias('appData'),
    appStates: {
      showWelcomePage: false
    },

    days: Ember.computed('appData.childApps.@each.childAppIndex', 'appData.subAppCount', function () {
      var days = Ember.A();
      var subAppCount = this.get('appData.subAppCount');
      var index = 1;
      while (index <= subAppCount) {
        var subApp = this.get('appData.childApps').findBy("childAppIndex", index);
        var subAppDates = this.get('appData.childAppDates') ? this.get('appData.childAppDates').findBy("childAppIndex", index) : null;
        days.push({
          day: index,
          app: subApp,
          appDates: subAppDates || subApp
        });
        index++;
      }
      return days;
    }),
    editMode: false,
    didRender: function didRender() {
      this.get("tracking").initLinkTracking();
      if (this.get('editMode')) {
        window.$('#' + this.elementId + ' [data-toggle="tooltip"]').tooltip();

        if (this.get("appData.dragEnabled")) {
          var that = this;
          // dragable
          window.$('.door').draggable({
            containment: ".door-container",
            stop: function stop(event, ui) {
              var doorClass = event.target.className.match(/door-\d*/)[0];
              var doorClassPath = ".calendar-view .calendar .door-container";
              if (that.get("appData.portraitModeEnabled") && that.get("editOrientation") === 'portrait') {
                doorClassPath = ".orientation-" + that.get("editOrientation") + ".portrait-mode-enabled " + doorClassPath;
              }
              var door = window.$(doorClassPath + " ." + doorClass)[0];
              var doorTop = door.offsetTop;
              var doorLeft = door.offsetLeft;
              var doorContainer = window.$(".calendar-view .calendar .door-container");
              var doorContainerWidth = doorContainer.width();
              var doorContainerHeight = doorContainer.height();

              var doorLeftPercent = Math.round(doorLeft * 100 / doorContainerWidth * 100) / 100;
              var doorTopPercent = Math.round(doorTop * 100 / doorContainerHeight * 100) / 100;

              console.log(doorLeftPercent);
              console.log(doorTopPercent);

              var customStyles = that.get("appData.customStyles") || "";

              var styleTemplate = "\n/*generated*/" + doorClassPath + " .door." + doorClass;

              // assure position absolut
              var styleRegExp2 = new RegExp(styleTemplate.replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + " \{ position:absolute; margin:0; \}", "g");
              if (!customStyles.match(styleRegExp2)) {
                customStyles = styleTemplate + " { position:absolute; margin:0; }\n" + customStyles;
              }

              // add door style
              var styleRegExp = new RegExp((styleTemplate + "." + doorClass).replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + " \{.*\}", "g");
              customStyles = customStyles.replace(styleRegExp, "");
              var newDoorStyle = styleTemplate + "." + doorClass + " { left:" + doorLeftPercent + "%; top:" + doorTopPercent + "%;}";
              customStyles += newDoorStyle;

              that.set("appData.customStyles", customStyles);

              // adjust door setting
              Ember.run.later(function () {
                window.$(door).css("top", "");
                window.$(door).css("left", "");
              });
            }
          });
          window.$('.door').resizable({
            stop: function stop(event) {
              var doorClass = event.target.className.match(/door-\d*/)[0];
              var doorClassPath = ".calendar-view .calendar .door-container";
              if (that.get("appData.portraitModeEnabled") && that.get("editOrientation") === 'portrait') {
                doorClassPath = ".orientation-" + that.get("editOrientation") + " " + doorClassPath;
              }
              var door = window.$(doorClassPath + " ." + doorClass)[0];
              var doorWidth = window.$(door).width();
              var doorHeight = window.$(door).height();
              var doorContainer = window.$(doorClassPath);
              var doorContainerWidth = doorContainer.width();
              var doorContainerHeight = doorContainer.height();

              var doorWidthPercent = Math.round(doorWidth * 100 / doorContainerWidth * 100) / 100;
              var doorHeightPercent = Math.round(doorHeight * 100 / doorContainerHeight * 100) / 100;

              var customStyles = that.get("appData.customStyles") || "";

              var styleTemplate = "\n/*generated-size*/" + doorClassPath + " .door." + doorClass;

              // add door style
              var styleRegExp = new RegExp(styleTemplate.replace(/\./g, "\\.").replace(/\//g, "\\/").replace(/\*/g, "\\*") + " \{.*\}", "g");
              customStyles = customStyles.replace(styleRegExp, "");
              var newDoorStyle = styleTemplate + " { width:" + doorWidthPercent + "%; height:" + doorHeightPercent + "%;}";
              customStyles += newDoorStyle;

              that.set("appData.customStyles", customStyles);

              // adjust door setting
              Ember.run.later(function () {
                window.$(door).css("width", "");
                window.$(door).css("height", "");
              });
            }
          });
        }
      }
    },
    init: function init() {
      this._super();
      this.set("appStates.showWelcomePage", this.get('appData.welcomePage') && !this.get('editMode'));

      this.setFromNow();

      if (!this.get('editMode') && this.get('appData.id') != null) {
        this.get('store').createRecord("view", { app: this.get('appData') }).save();
      }
    },
    dayChange: Ember.observer("selectedApp", function () {
      var _this = this;

      var tokens = Ember.get(this, 'pageTitleList');
      if (this.get("selectedApp")) {
        tokens.push({
          id: "selectedAppTitle",
          title: this.get("i18n").tu('title', this.get("selectedApp.title"), this.get("selectedApp")),
          prepend: false
        });
      } else {
        if (tokens.tokens.findBy('id', "selectedAppTitle")) {
          tokens.remove("selectedAppTitle");
        }
      }

      Ember.run.later(function () {
        _this.set("door", _this.get("selectedApp") ? _this.get("selectedApp.childAppIndex") : null);
        if (!_this.get("selectedApp")) {
          _this.set("tab", null);
          _this.set("step", 1);
        }
      });
    }).on("init"),

    setFromNow: function setFromNow() {
      var self = this;

      var startDate = (0, _moment.default)(this.get('appData.startDate'));
      var stringVal = (0, _moment.default)(this.get('appData.startDate')).fromNow();
      var now = (0, _moment.default)();
      var started = now.isAfter(startDate);
      var days = startDate.diff(now, 'days');
      now = now.add(days, 'days'); // hours
      var hours = startDate.diff(now, 'hours');
      now = now.add(hours, 'hours');
      var minutes = startDate.diff(now, 'minutes');
      now = now.add(minutes, 'minutes');
      var seconds = startDate.diff(now, 'seconds');
      if (!this.get("isDestroyed")) {
        this.set('fromNow', {
          started: started,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
          string: stringVal
        });

        Ember.run.later(function () {
          self.setFromNow();
        }, 1000);
      }
    },
    editSubApp: "editSubApp",
    actions: {
      doorContainerResized: function doorContainerResized() {
        if (!this.get("inEditor")) {
          this.adjustToScreenSize();
        }
        this.centerModals();
        this.calculateDoorPositionVars();
      },

      hideWelcomePage: function hideWelcomePage() {
        this.get("embed").notifyStepChange();
        this.set("appStates.showWelcomePage", false);
      },
      editDay: function editDay(index) {
        this.sendAction("editSubApp", index, {
          impressum: "-",
          conditions: "-"
        });
      },
      showDay: function showDay(index) {
        var _this2 = this;

        var selectedAppDate = this.get('appData.childAppDates').findBy("childAppIndex", index);
        var selectedApp = this.get('appData.childApps').findBy("childAppIndex", index);
        if (!selectedAppDate && selectedApp) {
          selectedAppDate = {
            startDate: selectedApp.get("startDate"),
            endDate: selectedApp.get("endDate")
          };
        }

        if (!selectedAppDate) {
          window.$('#' + this.elementId + ' #cc-noAction').modal().on("shown.bs.modal", function () {
            _this2.centerModal(window.$('#' + _this2.elementId + ' #cc-noAction'));
          });
        } else if (!this.get('appData.demo') && new Date(selectedAppDate.startDate) > new Date() && !this.get('editMode')) {
          window.$('#' + this.elementId + ' #cc-toEarly').modal().on("shown.bs.modal", function () {
            _this2.centerModal(window.$('#' + _this2.elementId + ' #cc-toEarly'));
          });
        } else if (!this.get('appData.demo') && new Date(selectedAppDate.endDate) < new Date() && !this.get('editMode')) {
          window.$('#' + this.elementId + ' #cc-toLate').modal().on("shown.bs.modal", function () {
            _this2.centerModal(window.$('#' + _this2.elementId + ' #cc-toLate'));
          });
        } else {
          if (!selectedApp && this.get("appData.demo")) {
            // in demo modus load childApp if not loaded yet
            this.get("store").findRecord("apps/" + selectedAppDate.childAppType, selectedAppDate.childAppId).then(function (app) {
              app.set("parentApp", _this2.get("appData")); // we do not want to load it again
              if (app.get("appType") === "directLink") {
                var win = window.open(app.get("link"), app.get("openInTarget"));
                win.focus();
              } else {
                _this2.set('selectedApp', app);
                window.$('#' + _this2.elementId + ' #cc-subApp').modal().on('hide.bs.modal', function () {
                  _this2.set("selectedApp", null);
                });
              }
            });
          } else if (selectedApp) {
            selectedApp.set("parentApp", this.get("appData")); // we do not want to load it again
            if (selectedApp.get("appType") === "directLink") {
              var win = window.open(selectedApp.get("link"), selectedApp.get("openInTarget"));
              win.focus();
            } else {
              this.set('selectedApp', selectedApp);
              window.$('#' + this.elementId + ' #cc-subApp').modal().on('hide.bs.modal', function () {
                _this2.set("selectedApp", null);
              }).on("shown.bs.modal", function () {
                _this2.centerModal(window.$('#' + _this2.elementId + ' #cc-subApp'));
              });
            }
          }
        }
      }
    },
    centerModals: function centerModals() {
      var _this3 = this;

      $(".modal").each(function (it, modal) {
        _this3.centerModal(modal);
      });
    },
    centerModal: function centerModal(modal) {
      return; // centerModals is handled by css class ".auto-center-modals" now
      if (this.get("appData.autoCenterModals")) {
        var modalHeight = $(modal).height();
        var modalDialogHeight = $(modal).find(".modal-dialog").height();
        var marginTop = (modalHeight - modalDialogHeight) / 2;
        if (marginTop < 0) {
          marginTop = 0;
        }
        $(modal).find(".modal-dialog").animate({ "margin-top": marginTop + "px" }, 250);
      }
    },
    calculateDoorPositionVars: function calculateDoorPositionVars() {

      var cssVars = "";
      var doorContainerWith = $('.door-container').width();
      $(".door").each(function (i, door) {
        var pos = $(door).position();
        var className = door.className.match("door-[0-9]+");
        cssVars += "--" + className + "-top:" + pos.top + "px;\n";
        cssVars += "--" + className + "-left:" + pos.left + "px;\n";
      });
      cssVars += "--doorContainer-width:" + doorContainerWith + "px;";

      this.set("doorCssVars", cssVars);
    },

    adjustToScreenSize: function adjustToScreenSize() {
      if (this.get("embed.isEmbedded")) {
        return;
      }
      var appView = Ember.$(".app-view");
      var windowHeight = window.hc2ParentWindowHeight || Ember.$(window).height();

      var calendar = Ember.$(".calendar");

      if (calendar.size()) {
        var offsetTop = calendar.offset().top;
        var availableHeight = windowHeight - offsetTop;

        var calendarBackgroundImage = $('.backgroundImage', calendar);
        var imgHeight = calendarBackgroundImage[0].naturalHeight;
        var imgWidth = calendarBackgroundImage[0].naturalWidth;
        var appViewWidth = appView.width();

        if (imgHeight) {

          var percentToAvailableHeight = 100 / imgHeight * availableHeight;
          var percentToAvailableWidth = 100 / imgWidth * appViewWidth;

          var fullSizeWidth = imgWidth * availableHeight / imgHeight;
          var maxWidth = fullSizeWidth > appViewWidth ? appViewWidth : fullSizeWidth;
          var maxWidthStyle = fullSizeWidth > appViewWidth ? "100%" : fullSizeWidth + "px";
          Ember.$(".calendar-size-wrapper").width(maxWidthStyle);
          this.set("viewSize", maxWidth < 768 ? 'xs' : '');
        }
      }
    },
    addResizeHandler: function () {
      var _this4 = this;

      if (!this.get("inEditor")) {
        window.$(window).on("resize", function () {
          return _this4.adjustToScreenSize();
        });
      }
    }.on("init"),
    willDestroyElement: function willDestroyElement() {
      window.$(window).off('resize');
    }
  });
});