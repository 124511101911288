define('hc2-ui/components/controls/style-controls', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // properties beginning with "styleSettings." are handled as labels
    colorProperties: ['styleSettings.colors', 'colorMain', 'colorSecondary', 'colorText', 'styleSettings.links', 'colorLinks', 'colorLinksHover', 'switchLinksUnderline', 'switchLinksUnderlineHover', 'styleSettings.buttons', 'colorBtn', 'colorBtnText', 'colorBtnHover', 'colorBtnTextHover', 'styleSettings.formElements', 'colorRadio', 'colorCheckbox', 'colorInputField', 'colorInputFieldActive', 'colorInputFieldIcons', 'styleSettings.other', 'colorProgressBar', 'switchHideNavbar', 'switchHideFooter'],
    properties: Ember.computed('before', 'after', 'appData.parentApp', function () {
      var propertiesArray = [];
      if (this.get('before')) {
        propertiesArray = propertiesArray.concat(this.get('before'));
      }
      if (!this.get("appData.parentApp.content")) {
        propertiesArray = propertiesArray.concat(this.get('colorProperties'));
      }
      if (this.get('after')) {
        propertiesArray = propertiesArray.concat(this.get('after'));
      }
      return propertiesArray;
    }),
    actions: {
      setConfig: function setConfig(key, value) {
        this.set("appData.config." + key, value);
      }
    }
  });
});