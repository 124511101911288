define("hc2-ui/templates/components/apps/memory/app-view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("style");
          dom.setAttribute(el1, "scoped", "true");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["content", "appData.customStyles", ["loc", [null, [5, 16], [5, 40]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 2
            },
            "end": {
              "line": 11,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "apps/welcome-page", [], ["appData", ["subexpr", "@mut", [["get", "appData", ["loc", [null, [10, 32], [10, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "hideWelcomePage", ["subexpr", "action", ["hideWelcomePage"], [], ["loc", [null, [10, 56], [10, 82]]], 0, 0], "editMode", ["subexpr", "@mut", [["get", "editMode", ["loc", [null, [10, 92], [10, 100]]], 0, 0, 0, 0]], [], [], 0, 0], "renderLocaleSwitcher", true], ["loc", [null, [10, 4], [10, 128]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 14,
                  "column": 4
                },
                "end": {
                  "line": 18,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "headerImage");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element13, 'src');
              return morphs;
            },
            statements: [["attribute", "src", ["concat", [["subexpr", "tu", ["headerImageUri", ["get", "appData.headerImageUri", ["loc", [null, [16, 40], [16, 62]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [16, 63], [16, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 18], [16, 72]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 4
                },
                "end": {
                  "line": 23,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "headerContent");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "tu", ["headerContent", ["get", "appData.headerContent", ["loc", [null, [21, 30], [21, 51]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [21, 52], [21, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 8], [21, 62]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 25,
                  "column": 4
                },
                "end": {
                  "line": 27,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h1");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "tu", ["title", ["get", "appData.title", ["loc", [null, [26, 21], [26, 34]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [26, 35], [26, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 8], [26, 44]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child3 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 8
                  },
                  "end": {
                    "line": 36,
                    "column": 8
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "active");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "href", "#tab-ss-description");
                dom.setAttribute(el2, "data-toggle", "tab");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element9);
                morphs[1] = dom.createMorphAt(element9, 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["setSelectedTab", "description"], [], ["loc", [null, [33, 60], [33, 101]]], 0, 0], ["inline", "tu", ["descriptionHeader", ["get", "appData.descriptionHeader", ["loc", [null, [33, 127], [33, 152]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [33, 153], [33, 160]]], 0, 0, 0, 0]], [], ["loc", [null, [33, 102], [33, 162]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 8
                  },
                  "end": {
                    "line": 49,
                    "column": 8
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("a");
                dom.setAttribute(el2, "href", "#tab-ss-additionalInformation");
                dom.setAttribute(el2, "data-toggle", "tab");
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1, 1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element8);
                morphs[1] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["setSelectedTab", "additionalInformation"], [], ["loc", [null, [45, 74], [45, 125]]], 0, 0], ["inline", "tu", ["additionalInformationHeader", ["get", "appData.additionalInformationHeader", ["loc", [null, [46, 61], [46, 96]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [46, 97], [46, 104]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 26], [46, 106]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 4
                },
                "end": {
                  "line": 51,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "nav nav-tabs");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "href", "#tab-ss-participate");
              dom.setAttribute(el3, "data-toggle", "tab");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element10 = dom.childAt(fragment, [1]);
              var element11 = dom.childAt(element10, [3]);
              var element12 = dom.childAt(element11, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createMorphAt(element10, 1, 1);
              morphs[1] = dom.createAttrMorph(element11, 'class');
              morphs[2] = dom.createElementMorph(element12);
              morphs[3] = dom.createMorphAt(element12, 1, 1);
              morphs[4] = dom.createMorphAt(element10, 5, 5);
              return morphs;
            },
            statements: [["block", "if", [["get", "appData.descriptionHeader", ["loc", [null, [31, 14], [31, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [31, 8], [36, 15]]]], ["attribute", "class", ["concat", [["subexpr", "unless", [["get", "appData.descriptionHeader", ["loc", [null, [38, 28], [38, 53]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [38, 19], [38, 64]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["setSelectedTab", "participate"], [], ["loc", [null, [39, 60], [39, 101]]], 0, 0], ["inline", "tu", ["participate_nav_label", ["subexpr", "ta", ["sweepstake.participate"], [], ["loc", [null, [40, 43], [40, 72]]], 0, 0], ["get", "appData", ["loc", [null, [40, 73], [40, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 14], [40, 82]]], 0, 0], ["block", "if", [["get", "appData.additionalInformationHeader", ["loc", [null, [43, 14], [43, 49]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [43, 8], [49, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child4 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 8
                },
                "end": {
                  "line": 57,
                  "column": 8
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "tab-pane fade in active");
              dom.setAttribute(el1, "id", "tab-ss-description");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "tu", ["description", ["get", "appData.description", ["loc", [null, [55, 32], [55, 51]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [55, 52], [55, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [55, 12], [55, 62]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child5 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 12
                },
                "end": {
                  "line": 69,
                  "column": 12
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "games/memory-game", [], ["pairs", ["subexpr", "@mut", [["get", "appData.pairs", ["loc", [null, [64, 26], [64, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "editMode", ["subexpr", "@mut", [["get", "editMode", ["loc", [null, [65, 29], [65, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "backgroundImage", ["subexpr", "@mut", [["get", "appData.cardBackgroundImageUri", ["loc", [null, [66, 36], [66, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "onDone", ["subexpr", "action", ["onGameDone"], [], ["loc", [null, [67, 27], [67, 48]]], 0, 0]], ["loc", [null, [63, 12], [68, 14]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child6 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 20
                    },
                    "end": {
                      "line": 77,
                      "column": 20
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "error text-danger m-b-10");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "ta", [["subexpr", "concat", [["get", "error.message", ["loc", [null, [75, 41], [75, 54]]], 0, 0, 0, 0], ".sweepstake.participation"], [], ["loc", [null, [75, 33], [75, 83]]], 0, 0]], ["attribute", ["subexpr", "t", [["get", "error.attribute", ["loc", [null, [75, 97], [75, 112]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 94], [75, 113]]], 0, 0]], ["loc", [null, [75, 28], [75, 115]]], 0, 0]],
                locals: ["error"],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 80,
                      "column": 28
                    },
                    "end": {
                      "line": 80,
                      "column": 92
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createElement("i");
                  dom.setAttribute(el1, "class", "fa fa-spinner fa-spin");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 69,
                    "column": 12
                  },
                  "end": {
                    "line": 85,
                    "column": 12
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("form");
                dom.setAttribute(el1, "role", "form");
                dom.setAttribute(el1, "class", "participate-form");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "participateButton");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("a");
                dom.setAttribute(el3, "class", "btn btn-primary");
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                            ");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                        ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var element7 = dom.childAt(element6, [7, 1]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(element6, 1, 1);
                morphs[1] = dom.createMorphAt(element6, 3, 3);
                morphs[2] = dom.createMorphAt(element6, 5, 5);
                morphs[3] = dom.createElementMorph(element7);
                morphs[4] = dom.createMorphAt(element7, 1, 1);
                morphs[5] = dom.createMorphAt(element7, 3, 3);
                return morphs;
              },
              statements: [["inline", "apps/duration-timer", [], ["duration", ["subexpr", "@mut", [["get", "duration", ["loc", [null, [71, 51], [71, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "static", true], ["loc", [null, [71, 20], [71, 73]]], 0, 0], ["inline", "apps/contact-form", [], ["appData", ["subexpr", "@mut", [["get", "appData", ["loc", [null, [72, 48], [72, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "captchaReloadTriggerDate", ["subexpr", "@mut", [["get", "captchaReloadTriggerDate", ["loc", [null, [72, 81], [72, 105]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [72, 20], [72, 107]]], 0, 0], ["block", "each", [["get", "participation.errors", ["loc", [null, [73, 28], [73, 48]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [73, 20], [77, 29]]]], ["element", "action", ["submitForm"], [], ["loc", [null, [79, 51], [79, 74]]], 0, 0], ["block", "if", [["get", "savingParticipation", ["loc", [null, [80, 34], [80, 53]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [80, 28], [80, 99]]]], ["inline", "ta", ["sweepstake.participate"], [], ["loc", [null, [81, 28], [81, 59]]], 0, 0]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 88,
                        "column": 20
                      },
                      "end": {
                        "line": 92,
                        "column": 20
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "track-links");
                    var el2 = dom.createTextNode("\n                            ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
                    return morphs;
                  },
                  statements: [["inline", "replace-placeholders", [["subexpr", "tu", ["thanksText", ["get", "appData.thanksText", ["loc", [null, [90, 69], [90, 87]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [90, 88], [90, 95]]], 0, 0, 0, 0]], [], ["loc", [null, [90, 52], [90, 96]]], 0, 0], ["get", "participation", ["loc", [null, [90, 97], [90, 110]]], 0, 0, 0, 0], ["get", "participation.id", ["loc", [null, [90, 111], [90, 127]]], 0, 0, 0, 0]], [], ["loc", [null, [90, 28], [90, 130]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 92,
                        "column": 20
                      },
                      "end": {
                        "line": 94,
                        "column": 20
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                      ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "thanks-default-text");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "ta", ["sweepstake.thanks.defaultText"], [], ["loc", [null, [93, 55], [93, 93]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 85,
                      "column": 12
                    },
                    "end": {
                      "line": 101,
                      "column": 12
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "thanks-pane");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "resetLink");
                  var el3 = dom.createElement("a");
                  dom.setAttribute(el3, "class", "btn btn-primary");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "sharerBox");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n            ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var element5 = dom.childAt(element4, [5, 0]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element4, 1, 1);
                  morphs[1] = dom.createMorphAt(element4, 3, 3);
                  morphs[2] = dom.createAttrMorph(element5, 'onclick');
                  morphs[3] = dom.createMorphAt(element5, 0, 0);
                  morphs[4] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "apps/duration-timer", [], ["duration", ["subexpr", "@mut", [["get", "duration", ["loc", [null, [87, 51], [87, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "static", true], ["loc", [null, [87, 20], [87, 73]]], 0, 0], ["block", "if", [["get", "appData.thanksText", ["loc", [null, [88, 26], [88, 44]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [88, 20], [94, 27]]]], ["attribute", "onclick", ["subexpr", "action", ["reset"], [], ["loc", [null, [null, null], [95, 96]]], 0, 0], 0, 0, 0, 0], ["inline", "ta", ["memory.resetApp"], [], ["loc", [null, [95, 97], [95, 121]]], 0, 0], ["inline", "controls/add-to-any", [], ["app", ["subexpr", "@mut", [["get", "appData", ["loc", [null, [99, 46], [99, 53]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [99, 20], [99, 55]]], 0, 0]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 85,
                    "column": 12
                  },
                  "end": {
                    "line": 101,
                    "column": 12
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "showThanks", ["loc", [null, [85, 22], [85, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [85, 12], [101, 12]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 12
                },
                "end": {
                  "line": 101,
                  "column": 12
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "showParticipationForm", ["loc", [null, [69, 22], [69, 43]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [69, 12], [101, 12]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child7 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 111,
                  "column": 4
                },
                "end": {
                  "line": 115,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "sweepstake-footer");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "tu", ["footer", ["get", "appData.footer", ["loc", [null, [113, 23], [113, 37]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [113, 38], [113, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [113, 8], [113, 48]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child8 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 120,
                    "column": 8
                  },
                  "end": {
                    "line": 126,
                    "column": 8
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "col-sm-3 col-xs-6");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "sharerBox sharerBox-small");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "controls/add-to-any", [], ["app", ["subexpr", "@mut", [["get", "appData", ["loc", [null, [123, 40], [123, 47]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [123, 14], [123, 49]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 117,
                  "column": 4
                },
                "end": {
                  "line": 143,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment(" Button trigger modal ");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "ss-control-footer control-footer row m-l-10 m-r-10");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "data-toggle", "modal");
              dom.setAttribute(el3, "href", "#ss-conditions");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "icon icons-documents-bookmarks-01");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "data-toggle", "modal");
              dom.setAttribute(el3, "href", "#ss-privacyPolicy");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "icon icons-documents-bookmarks-01");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "data-toggle", "modal");
              dom.setAttribute(el3, "href", "#ss-impressum");
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "icon icons-faces-users-03");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("span");
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [3]);
              var element1 = dom.childAt(element0, [3]);
              var element2 = dom.childAt(element0, [5]);
              var element3 = dom.childAt(element0, [7]);
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(element0, 1, 1);
              morphs[1] = dom.createAttrMorph(element1, 'class');
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [1, 2]), 0, 0);
              morphs[3] = dom.createAttrMorph(element2, 'class');
              morphs[4] = dom.createMorphAt(dom.childAt(element2, [1, 2]), 0, 0);
              morphs[5] = dom.createAttrMorph(element3, 'class');
              morphs[6] = dom.createMorphAt(dom.childAt(element3, [1, 2]), 0, 0);
              return morphs;
            },
            statements: [["block", "if", [["get", "appData.showSharingButtons", ["loc", [null, [120, 14], [120, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [120, 8], [126, 15]]]], ["attribute", "class", ["concat", ["col-sm-", ["subexpr", "if", [["get", "appData.showSharingButtons", ["loc", [null, [127, 32], [127, 58]]], 0, 0, 0, 0], "3 text-center text-xs-right", "4"], [], ["loc", [null, [127, 27], [127, 94]]], 0, 0], "  col-xs-6"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "ta", ["conditions"], [], ["loc", [null, [129, 71], [129, 90]]], 0, 0], ["attribute", "class", ["concat", ["col-sm-", ["subexpr", "if", [["get", "appData.showSharingButtons", ["loc", [null, [132, 32], [132, 58]]], 0, 0, 0, 0], "3 text-center text-xs-right", "4"], [], ["loc", [null, [132, 27], [132, 94]]], 0, 0], "  col-xs-12"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "ta", ["privacyPolicy"], [], ["loc", [null, [134, 67], [134, 89]]], 0, 0], ["attribute", "class", ["concat", ["col-sm-", ["subexpr", "if", [["get", "appData.showSharingButtons", ["loc", [null, [137, 32], [137, 58]]], 0, 0, 0, 0], "3", "4"], [], ["loc", [null, [137, 27], [137, 68]]], 0, 0], "  col-xs-12 text-right"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "ta", ["impressum"], [], ["loc", [null, [139, 63], [139, 81]]], 0, 0]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 4
              },
              "end": {
                "line": 144,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "tab-content");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment(" PARTICIPATE TAB ");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "id", "tab-ss-participate");
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment(" END MEMORY ");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "tab-pane fade in");
            dom.setAttribute(el2, "id", "tab-ss-additionalInformation");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element14 = dom.childAt(fragment, [8]);
            var element15 = dom.childAt(element14, [5]);
            var morphs = new Array(10);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
            morphs[4] = dom.createMorphAt(element14, 1, 1);
            morphs[5] = dom.createAttrMorph(element15, 'class');
            morphs[6] = dom.createMorphAt(element15, 1, 1);
            morphs[7] = dom.createUnsafeMorphAt(dom.childAt(element14, [9]), 1, 1);
            morphs[8] = dom.createMorphAt(fragment, 10, 10, contextualElement);
            morphs[9] = dom.createMorphAt(fragment, 12, 12, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "appData.headerImageUri", ["loc", [null, [14, 10], [14, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 4], [18, 11]]]], ["block", "if", [["subexpr", "has-html-content", [["get", "appData.headerContent", ["loc", [null, [19, 28], [19, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 10], [19, 50]]], 0, 0]], [], 1, null, ["loc", [null, [19, 4], [23, 11]]]], ["block", "if", [["get", "appData.showTitle", ["loc", [null, [25, 10], [25, 27]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [25, 4], [27, 11]]]], ["block", "if", [["get", "showTabs", ["loc", [null, [29, 10], [29, 18]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [29, 4], [51, 11]]]], ["block", "if", [["get", "appData.descriptionHeader", ["loc", [null, [53, 14], [53, 39]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [53, 8], [57, 15]]]], ["attribute", "class", ["concat", ["tab-pane fade in", ["subexpr", "unless", [["get", "appData.descriptionHeader", ["loc", [null, [60, 45], [60, 70]]], 0, 0, 0, 0], " active"], [], ["loc", [null, [60, 36], [60, 82]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "showBoard", ["loc", [null, [62, 18], [62, 27]]], 0, 0, 0, 0]], [], 5, 6, ["loc", [null, [62, 12], [101, 19]]]], ["inline", "tu", ["additionalInformation", ["get", "appData.additionalInformation", ["loc", [null, [108, 42], [108, 71]]], 0, 0, 0, 0], ["get", "appData", ["loc", [null, [108, 72], [108, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [108, 12], [108, 82]]], 0, 0], ["block", "if", [["get", "appData.footer", ["loc", [null, [111, 10], [111, 24]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [111, 4], [115, 11]]]], ["block", "unless", [["get", "appData.parentApp", ["loc", [null, [117, 14], [117, 31]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [117, 4], [143, 15]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 146,
                  "column": 4
                },
                "end": {
                  "line": 148,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ta", ["app.not.active.anymore"], [], ["loc", [null, [147, 6], [147, 39]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 148,
                  "column": 4
                },
                "end": {
                  "line": 150,
                  "column": 4
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "ta", ["app.not.active.yet"], [], ["loc", [null, [149, 6], [149, 33]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 144,
                "column": 4
              },
              "end": {
                "line": 152,
                "column": 4
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" not actrive ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "date-reached", [["get", "appData.endDate", ["loc", [null, [146, 24], [146, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [146, 10], [146, 40]]], 0, 0]], [], 0, 1, ["loc", [null, [146, 4], [150, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 156,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "m-r-10");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-or", [["get", "editMode", ["loc", [null, [12, 17], [12, 25]]], 0, 0, 0, 0], ["get", "active", ["loc", [null, [12, 26], [12, 32]]], 0, 0, 0, 0], ["get", "appData.isNew", ["loc", [null, [12, 33], [12, 46]]], 0, 0, 0, 0], ["get", "appData.parentApp", ["loc", [null, [12, 47], [12, 64]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 10], [12, 65]]], 0, 0]], [], 0, 1, ["loc", [null, [12, 4], [152, 11]]]], ["inline", "ml/app-locale-switcher", [], ["locales", ["subexpr", "@mut", [["get", "appData.localesParent", ["loc", [null, [154, 39], [154, 60]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [154, 6], [154, 62]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 159,
              "column": 2
            },
            "end": {
              "line": 161,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "tu", ["conditions", ["get", "appData.conditions", ["loc", [null, [160, 23], [160, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [160, 4], [160, 44]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 162,
              "column": 2
            },
            "end": {
              "line": 165,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "m-t-20 gaOptout");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
          return morphs;
        },
        statements: [["inline", "tu", ["privacyPolicy", ["get", "appData.privacyPolicy", ["loc", [null, [163, 26], [163, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [163, 4], [163, 50]]], 0, 0], ["content", "ga-optout", ["loc", [null, [164, 33], [164, 46]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child5 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 166,
              "column": 2
            },
            "end": {
              "line": 168,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "tu", ["impressum", ["get", "appData.impressum", ["loc", [null, [167, 22], [167, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [167, 4], [167, 42]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 169,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/memory/app-view.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "memory-view app-view");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" end welcomePage ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element16 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element16, 1, 1);
        morphs[1] = dom.createMorphAt(element16, 3, 3);
        morphs[2] = dom.createMorphAt(element16, 6, 6);
        morphs[3] = dom.createMorphAt(element16, 8, 8);
        morphs[4] = dom.createMorphAt(element16, 9, 9);
        morphs[5] = dom.createMorphAt(element16, 10, 10);
        return morphs;
      },
      statements: [["block", "if", [["get", "appData.customStyles", ["loc", [null, [3, 10], [3, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 4], [7, 11]]]], ["block", "if", [["get", "appStates.showWelcomePage", ["loc", [null, [9, 8], [9, 33]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [9, 2], [156, 9]]]], ["inline", "apps/cookie-info", [], ["appData", ["subexpr", "@mut", [["get", "appData", ["loc", [null, [157, 31], [157, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "appStates", ["subexpr", "@mut", [["get", "appStates", ["loc", [null, [157, 49], [157, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "modalId", "ss-privacyPolicy"], ["loc", [null, [157, 4], [157, 87]]], 0, 0], ["block", "-el/modal", [], ["modalId", "ss-conditions", "title", ["subexpr", "ta", ["conditions"], [], ["loc", [null, [159, 45], [159, 62]]], 0, 0], "closeLabel", ["subexpr", "ta", ["close"], [], ["loc", [null, [159, 74], [159, 86]]], 0, 0]], 3, null, ["loc", [null, [159, 2], [161, 16]]]], ["block", "-el/modal", [], ["modalId", "ss-privacyPolicy", "title", ["subexpr", "ta", ["privacyPolicy"], [], ["loc", [null, [162, 48], [162, 68]]], 0, 0], "closeLabel", ["subexpr", "ta", ["close"], [], ["loc", [null, [162, 80], [162, 92]]], 0, 0]], 4, null, ["loc", [null, [162, 2], [165, 16]]]], ["block", "-el/modal", [], ["modalId", "ss-impressum", "title", ["subexpr", "ta", ["impressum"], [], ["loc", [null, [166, 44], [166, 60]]], 0, 0], "closeLabel", ["subexpr", "ta", ["close"], [], ["loc", [null, [166, 72], [166, 84]]], 0, 0]], 5, null, ["loc", [null, [166, 2], [168, 16]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  }());
});