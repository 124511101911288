define("hc2-ui/templates/components/apps/participation-data-view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 10,
                      "column": 8
                    },
                    "end": {
                      "line": 12,
                      "column": 8
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode(":\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["locale"], [], ["loc", [null, [11, 10], [11, 24]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 12,
                        "column": 8
                      },
                      "end": {
                        "line": 14,
                        "column": 8
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(" ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode(":\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "t", ["question.labeltext"], [], ["loc", [null, [13, 10], [13, 36]]], 0, 0], ["inline", "add-val", [1, ["subexpr", "object-at", [["subexpr", "split-by", [["get", "key", ["loc", [null, [13, 70], [13, 73]]], 0, 0, 0, 0], "-"], [], ["loc", [null, [13, 60], [13, 78]]], 0, 0], 1], [], ["loc", [null, [13, 49], [13, 81]]], 0, 0]], [], ["loc", [null, [13, 37], [13, 83]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 14,
                          "column": 8
                        },
                        "end": {
                          "line": 16,
                          "column": 8
                        }
                      },
                      "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(":\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "get", [["subexpr", "object-at", [["get", "participation.app.contactData.extraFromElements", ["loc", [null, [15, 27], [15, 74]]], 0, 0, 0, 0], ["subexpr", "object-at", [["subexpr", "split-by", [["get", "key", ["loc", [null, [15, 96], [15, 99]]], 0, 0, 0, 0], "-"], [], ["loc", [null, [15, 86], [15, 104]]], 0, 0], 1], [], ["loc", [null, [15, 75], [15, 107]]], 0, 0]], [], ["loc", [null, [15, 16], [15, 108]]], 0, 0], "label"], [], ["loc", [null, [15, 10], [15, 119]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                }();
                var child1 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.8.3",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 16,
                          "column": 8
                        },
                        "end": {
                          "line": 18,
                          "column": 8
                        }
                      },
                      "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("          ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode(":\n        ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["inline", "t", [["subexpr", "concat", ["sweepstake.contact.", ["get", "key", ["loc", [null, [17, 44], [17, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 14], [17, 48]]], 0, 0]], [], ["loc", [null, [17, 10], [17, 50]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 14,
                        "column": 8
                      },
                      "end": {
                        "line": 18,
                        "column": 8
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "starts-with", [["get", "key", ["loc", [null, [14, 31], [14, 34]]], 0, 0, 0, 0], "extraFromElement"], [], ["loc", [null, [14, 18], [14, 54]]], 0, 0]], [], 0, 1, ["loc", [null, [14, 8], [18, 8]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 12,
                      "column": 8
                    },
                    "end": {
                      "line": 18,
                      "column": 8
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "starts-with", [["get", "key", ["loc", [null, [12, 31], [12, 34]]], 0, 0, 0, 0], "answer"], [], ["loc", [null, [12, 18], [12, 44]]], 0, 0]], [], 0, 1, ["loc", [null, [12, 8], [18, 8]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            var child2 = function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 6
                    },
                    "end": {
                      "line": 23,
                      "column": 6
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", [["subexpr", "concat", ["locales.", ["get", "value", ["loc", [null, [22, 31], [22, 36]]], 0, 0, 0, 0], ".label"], [], ["loc", [null, [22, 12], [22, 46]]], 0, 0]], [], ["loc", [null, [22, 8], [22, 48]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child3 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 23,
                        "column": 6
                      },
                      "end": {
                        "line": 25,
                        "column": 6
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("a");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element1, 'href');
                    morphs[1] = dom.createMorphAt(element1, 0, 0);
                    return morphs;
                  },
                  statements: [["attribute", "href", ["concat", ["mailto:", ["get", "value", ["loc", [null, [24, 26], [24, 31]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "value", ["loc", [null, [24, 35], [24, 44]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.8.3",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 25,
                        "column": 6
                      },
                      "end": {
                        "line": 27,
                        "column": 6
                      }
                    },
                    "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "value", ["loc", [null, [26, 8], [26, 17]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 23,
                      "column": 6
                    },
                    "end": {
                      "line": 27,
                      "column": 6
                    }
                  },
                  "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "is-eq", ["email", ["get", "key", ["loc", [null, [23, 31], [23, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 16], [23, 35]]], 0, 0]], [], 0, 1, ["loc", [null, [23, 6], [27, 6]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 2
                  },
                  "end": {
                    "line": 30,
                    "column": 2
                  }
                },
                "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("th");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "is-eq", ["locale", ["get", "key", ["loc", [null, [10, 30], [10, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 14], [10, 34]]], 0, 0]], [], 0, 1, ["loc", [null, [10, 8], [18, 15]]]], ["block", "if", [["subexpr", "is-eq", ["locale", ["get", "key", ["loc", [null, [21, 28], [21, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 12], [21, 32]]], 0, 0]], [], 2, 3, ["loc", [null, [21, 6], [27, 13]]]]],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 2
                },
                "end": {
                  "line": 31,
                  "column": 2
                }
              },
              "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "unless", [["subexpr", "contains", [["get", "key", ["loc", [null, [7, 22], [7, 25]]], 0, 0, 0, 0], "captch"], [], ["loc", [null, [7, 12], [7, 35]]], 0, 0]], [], 0, null, ["loc", [null, [7, 2], [30, 13]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 2
              },
              "end": {
                "line": 32,
                "column": 2
              }
            },
            "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "unless", [["subexpr", "is-eq", ["totalPoints", ["get", "key", ["loc", [null, [6, 33], [6, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 12], [6, 37]]], 0, 0]], [], 0, null, ["loc", [null, [6, 2], [31, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 33,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["subexpr", "is-eq", ["conditions_accept", ["get", "key", ["loc", [null, [5, 39], [5, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 12], [5, 43]]], 0, 0]], [], 0, null, ["loc", [null, [5, 2], [32, 13]]]]],
        locals: ["key", "value"],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 10
            },
            "end": {
              "line": 43,
              "column": 6
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "moment-format", [["get", "participation.doiConfirmed", ["loc", [null, [42, 24], [42, 50]]], 0, 0, 0, 0], "L LT"], [], ["loc", [null, [42, 8], [42, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 6
            },
            "end": {
              "line": 44,
              "column": 6
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode(" -\n      ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 2
            },
            "end": {
              "line": 49,
              "column": 2
            }
          },
          "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "img-responsive");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["get", "participation.mediaUrl", ["loc", [null, [48, 16], [48, 38]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 50,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/components/apps/participation-data-view.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "participation-data-view");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("table");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("Id:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(":");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(":");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [5]);
        var element6 = dom.childAt(element4, [7]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(element4, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
        morphs[6] = dom.createMorphAt(element3, 3, 3);
        return morphs;
      },
      statements: [["content", "participation.id", ["loc", [null, [3, 24], [3, 44]]], 0, 0, 0, 0], ["block", "each-in", [["get", "participation.formData", ["loc", [null, [4, 13], [4, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 2], [33, 14]]]], ["inline", "t", ["participation.createdAt"], [], ["loc", [null, [36, 10], [36, 41]]], 0, 0], ["inline", "moment-format", [["get", "participation.dateCreated", ["loc", [null, [37, 26], [37, 51]]], 0, 0, 0, 0], "L LT"], [], ["loc", [null, [37, 10], [37, 60]]], 0, 0], ["inline", "t", ["participation.doiConfirmed"], [], ["loc", [null, [40, 10], [40, 44]]], 0, 0], ["block", "if", [["get", "participation.doiConfirmed", ["loc", [null, [41, 16], [41, 42]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [41, 10], [44, 13]]]], ["block", "if", [["get", "participation.mediaUrl", ["loc", [null, [47, 8], [47, 30]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [47, 2], [49, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});