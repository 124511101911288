define("hc2-ui/templates/external-content", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 6
          }
        },
        "moduleName": "hc2-ui/templates/external-content.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "consent-gate");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "consent-gate-info");
        dom.setAttribute(el3, "class", "text-center p-t-20 p-b-20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "text-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "class", "btn btn-danger btn-rounded");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "text-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("b");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3, 1]);
        var element4 = dom.childAt(element1, [5]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'style');
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[4] = dom.createAttrMorph(element3, 'onclick');
        morphs[5] = dom.createMorphAt(element3, 0, 0);
        morphs[6] = dom.createMorphAt(element4, 1, 1);
        morphs[7] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["consent-gate-wrapper ", ["subexpr", "if", [["subexpr", "video-img-url", [["get", "src", ["loc", [null, [1, 53], [1, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 38], [1, 57]]], 0, 0], "with-background-image"], [], ["loc", [null, [1, 33], [1, 83]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", [["subexpr", "if", [["subexpr", "video-img-url", [["get", "src", ["loc", [null, [1, 112], [1, 115]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 97], [1, 116]]], 0, 0], ["subexpr", "concat", ["background-image:url(", ["subexpr", "video-img-url", [["get", "src", ["loc", [null, [1, 164], [1, 167]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 149], [1, 168]]], 0, 0], ")"], [], ["loc", [null, [1, 117], [1, 173]]], 0, 0]], [], ["loc", [null, [1, 92], [1, 175]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["consent-gate.src-host-info"], [], ["loc", [null, [4, 12], [4, 46]]], 0, 0], ["content", "host", ["loc", [null, [4, 57], [4, 65]]], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["loadSrc"], [], ["loc", [null, [null, null], [7, 72]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["consent-gate.load-src"], [], ["loc", [null, [7, 73], [7, 102]]], 0, 0], ["inline", "input", [], ["type", "checkbox", "class", "md-checkbox", "checked", ["subexpr", "@mut", [["get", "allowHostAlways", ["loc", [null, [10, 58], [10, 73]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 6], [10, 75]]], 0, 0], ["content", "host", ["loc", [null, [10, 79], [10, 87]]], 0, 0, 0, 0], ["inline", "t", ["consent-gate.accept-always"], [], ["loc", [null, [10, 98], [10, 132]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});