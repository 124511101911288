define('hc2-ui/helpers/video-img-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    vimeoImages: {},
    config: Ember.inject.service(),

    compute: function compute(params /*, hash*/) {
      var videoUrl = params[0];
      if (!videoUrl) {
        return null;
      }
      var uploadHost = this.get('config').getENV().oauth.host;

      if (videoUrl.indexOf("www.youtube.com") > -1) {
        var videoUrlWithoutQuery = videoUrl.indexOf("?") > -1 ? videoUrl.substring(0, videoUrl.indexOf('?')) : videoUrl;
        var videoId = videoUrlWithoutQuery.match(/.*\/(.*)/)[1];
        return uploadHost + "/upload/ytThumbnail/" + videoId;
      }
      if (videoUrl.indexOf("player.vimeo.com") > -1) {
        var _videoId = videoUrl.match(/.*\/(.*)/)[1];
        return uploadHost + "/upload/vimeoThumbnailPiped/" + _videoId;
      }
    }
  });
});