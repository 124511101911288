define('hc2-ui/controllers/backend/platform', ['exports', 'hc2-ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    api: Ember.inject.service(),
    store: Ember.inject.service(),
    apiHost: _environment.default.oauth.host,
    i18n: Ember.inject.service(),

    testRecipient: "admin@happy-contests.de",

    visibleAuthorities: [{ name: 'DOI-Forced-Option', role: 'ROLE_DOI_FORCED', editable: true }, { name: 'EXTERNAL-UUID', role: 'ROLE_EXTERNAL_UUID', editable: true }, { name: 'EXPORT-IDS', role: 'ROLE_EXPORT_IDS', editable: true }, { name: 'PARTICIPATION_NOTICICATIONS', role: 'ROLE_PARTICIPATION_NOTICICATIONS', editable: true }, { name: 'CONTEST_HIGHLIGHT_TOP_VOTES', role: 'ROLE_CONTEST_HIGHLIGHT_TOP_VOTES', editable: true }, { name: 'WINCODES', role: 'ROLE_WINCODES', editable: true }],

    actions: {
      savePlatform: function savePlatform(platformData) {
        var _this = this;

        if (platformData.name) {
          this.get('store').createRecord("action", { action: "savePlatform", context: { platformData: platformData } }).save().then(function (result) {
            console.log(result.get("context"));
            if (platformData.new) {
              _this.transitionToRoute('backend.platform', result.get("context.id"));
            } else {
              window.location.reload();
            }
          }, function (r) {
            console.log(r);
            alert("action failed\n" + r.message);
          });
        } else {
          window.alert("Eine Platform muss einen Namen haben!");
        }
      },
      deletePlatform: function deletePlatform(platformData) {
        var _this2 = this;

        if (platformData.userCount === 0) {
          if (window.confirm("Wollen sie die Platform wirklich löschen!")) {
            this.get('store').createRecord("action", { action: "deletePlatform", context: { platformId: platformData.id } }).save().then(function () {
              window.alert("Platform gelöscht");
              _this2.transitionToRoute('backend.platforms');
            }, function (r) {
              console.log(r);
              alert("action failed\n" + r.message);
            });
          }
        } else {
          window.alert("Nur Platformen ohne User können gelöscht werden!");
        }
      },
      grantAuthority: function grantAuthority(authority, platformId) {
        if (window.confirm("Recht hinzufügen?")) {
          this.get('store').createRecord("action", { action: "grantPlatformAuthority", context: { platformId: platformId, authority: authority } }).save().then(function () {
            window.alert("Recht hinzugefügt");
            window.location.reload();
          }, function (r) {
            console.log(r);
            alert("action failed\n" + r.message);
          });
        }
      },
      removeAuthority: function removeAuthority(authority, platformId) {
        if (window.confirm("Recht entfernen?")) {
          this.get('store').createRecord("action", { action: "removePlatformAuthority", context: { platformId: platformId, authority: authority } }).save().then(function () {
            window.alert("Recht entfernt");
            window.location.reload();
          }, function (r) {
            console.log(r);
            alert("action failed\n" + r.message);
          });
        }
      }
    }
  });
});