define('hc2-ui/helpers/tu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    _locale: Ember.computed.readOnly('i18n.locale'),
    _appLocale: Ember.computed.readOnly('i18n.appLocale'),
    _appTranslations: Ember.computed.readOnly('i18n.app.translations'),

    compute: function compute(params) {
      var key = params[0];
      var appLocale = this.get("_appLocale");

      // check app translations
      var translations = this.get("_appTranslations");
      if (params[2]) {
        translations = params[2].get("translations");
      }
      if (translations) {
        var translationsKey = appLocale + "_appdata_" + key.replace(/\./g, "_");
        Ember.addObserver(translations, translationsKey, this, this.recompute);
        if (translations && translations[translationsKey]) {
          return this.safeContent(translations[translationsKey]);
        }
      }

      return this.safeContent(params[1]);
    },
    safeContent: function safeContent(string) {
      var lang = this.get("_appLocale");
      if (string && this.get("i18n.app.consentGate")) {
        var sources = string.match(/<iframe .*?src=".*?"/ig);
        if (sources) {
          sources.forEach(function printing(it, index) {
            var src = it.match(/src=".*?"/)[0];
            if (!src.startsWith('src="' + location.origin)) {

              var url = src.substring(5, src.length - 1);
              if (url.startsWith("//")) {
                url = location.protocol + url;
              }
              var urlO = new URL(url);

              var r = window.localStorage.getItem("trustedSources");
              var e = r ? JSON.parse(r) : [];
              if (e.indexOf(urlO.host) > -1) {
                return;
              }

              var newSrc = 'src="' + location.origin + '/external-content?d=true&src=' + encodeURIComponent(url) + '&lang=' + lang + '"';
              string = string.replaceAll(src, newSrc);
            }
          });
        }
      }
      return string;
    },

    _recomputeOnLocaleChange: Ember.observer('_locale', '_appLocale', '_appTranslations', 'i18n.app.consentGate', 'i18n.forceRecompute', function () {
      this.recompute();
    })
  });
});