define('hc2-ui/controllers/test', ['exports', 'hc2-ui/lib/constants/all-app-labels'], function (exports, _allAppLabels) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    edLab: Ember.computed(function () {
      return JSON.stringify((0, _allAppLabels.getAllAppLabels)(), null, 2);
    }),

    winWheelApi: {},

    actions: {
      start: function start() {
        this.get('winWheelApi').actions.startWheel();
      },
      stop: function stop() {
        this.get('winWheelApi').actions.stopWheel(7);
      },
      finished: function finished(segment) {
        window.alert("Prize: " + segment);
      }
    },
    chartData: Ember.computed(function () {
      //return this.getChartData([0,0,0,0,0,0,0,0,0]);
      return this.getChartData([10, 0, 20, 10, 10, 19, 16, 15, 0]);
      //return this.getChartData([12,23,23,2,1,21,1,21,54]);
    }),
    getChartData: function getChartData(values) {
      var defaultBackgroundColor = 'rgba(0, 0, 0, 0.4)';
      defaultBackgroundColor = 'rgba(75, 192, 192, 0.2)';
      var defaultBorderColor = 'rgba(0, 0, 0, 1)';
      defaultBorderColor = 'rgba(75, 192, 192, 1)';
      var valueLabel = '%';

      var valuesPercent = [];
      var labels = values;
      var labelAlign = [];
      var backgroundColor = [];
      var borderColor = [];

      var totalValues = 0;
      values.forEach(function (v) {
        totalValues += v;
      });

      var biggestValue = 0;
      values.forEach(function (v) {
        var val = Math.round(100 / totalValues * v);
        valuesPercent.push(Math.round(100 / totalValues * v));
        if (val > biggestValue) {
          biggestValue = val;
        }
      });

      // label alignment
      var alignLeftVal = biggestValue * 0.75;
      valuesPercent.forEach(function (v) {
        labelAlign.push(v > alignLeftVal ? 'start' : 'end');
      });

      var data = {
        hc2: { title: "test graph" },
        labels: labels,
        datasets: [{
          label: valueLabel,
          data: valuesPercent,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 1,
          datalabels: {
            anchor: 'end',
            align: labelAlign,
            display: true,
            clip: true,
            //clamp:true,
            formatter: function formatter(value) {
              return value + valueLabel;
            }
          }
        }]
      };
      return data;
    }
  });
});