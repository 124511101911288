define('hc2-ui/models/participation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    formData: _emberData.default.attr(),
    app: _emberData.default.belongsTo('apps/app'),
    dateCreated: _emberData.default.attr('date'),
    doiConfirmed: _emberData.default.attr('date'),
    domain: _emberData.default.attr('string'),
    prize: _emberData.default.attr('number'),
    mediaUrl: _emberData.default.attr('string'),
    winCode: _emberData.default.attr('string')
  });
});