define('hc2-ui/app', ['exports', 'hc2-ui/resolver', 'ember-load-initializers', 'hc2-ui/config/environment', 'ember-uuid'], function (exports, _resolver, _emberLoadInitializers, _environment, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var App = void 0;

  _environment.default.rid = (0, _emberUuid.v4)();
  Ember.$.ajaxSetup({
    headers: {
      'X-HC-RID': _environment.default.rid,
      'X-HC-REF': document.referrer
    }
  });
  Ember.$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (options.url) {
      var url = new URL(options.url).host.toLowerCase();
      if (url.endsWith("vimeo.com") || url.endsWith("captcha.happy-contests.de")) {
        delete options.headers['X-HC-RID'];
        delete options.headers['X-HC-REF'];
      }
    }
  });

  Ember.MODEL_FACTORY_INJECTIONS = true;

  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  Ember.LinkComponent.reopen({
    attributeBindings: ['data-toggle', 'data-modal', 'data-rel', 'data-placement', 'data-original-title']
  });
  Ember.TextField.reopen({
    attributeBindings: ["data-error-message", "data-required-pattern"]
  });
  Ember.Router.reopen({
    tracking: Ember.inject.service(),
    embed: Ember.inject.service(),
    pageTitleList: Ember.inject.service(),
    headData: Ember.inject.service(),
    notifyGoogleAnalytics: function () {
      this.set("tracking.firstTransitionDone", true);
      this.set("headData.title", Ember.get(this, 'pageTitleList').toString());
      this.get("tracking").trackPage();
      this.get("embed").notifyTransition();
    }.on('didTransition'),

    scrollToTop: function () {
      window.scrollTo(0, 0);
    }.on('didTransition')
  });

  Ember.Route.reopen({

    pageTitleList: Ember.inject.service(),
    headData: Ember.inject.service(),

    setupController: function setupController() {
      this._super.apply(this, arguments);

      var tokens = Ember.get(this, 'pageTitleList');

      var hash = {};
      var pageTitleProperty = this.get("pageTitle");
      if (pageTitleProperty) {

        if ((typeof pageTitleProperty === 'undefined' ? 'undefined' : _typeof(pageTitleProperty)) === "object" && !Ember.isArray(pageTitleProperty)) {
          hash = Ember.merge(hash, pageTitleProperty);
        } else {
          hash.title = Ember.isArray(pageTitleProperty) ? pageTitleProperty.join(this.get("pageTitleList.defaultSeparator")) : pageTitleProperty;
          hash.prepend = true;
        }
        hash.id = "routeId-" + this.get("routeName");

        this.set("_pageTitleToken", hash);

        tokens.push(hash);
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);
      if (this.get("_pageTitleToken")) {
        var tokens = Ember.get(this, 'pageTitleList');
        tokens.remove(this.get("_pageTitleToken.id"));
      }
    }
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});