define('hc2-ui/routes/backend/platform', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    api: Ember.inject.service(),
    pageTitle: "Platforms",
    model: function model(params) {
      if (params.platform_id === 'new') {
        return {
          platformData: {
            new: true,
            facebookPixelEnabled: false,
            availableApps: ""
          }
        };
      } else {
        return Ember.RSVP.hash({
          platformData: this.get("api").requestJSON("/statistics/platform/" + params.platform_id),
          platformAdmins: this.get("api").requestJSON("/statistics/platformAdmins/" + params.platform_id),
          platformUsers: this.get("api").requestJSON("/statistics/platformUsers/" + params.platform_id)
        });
      }
    },
    afterModel: function afterModel(model) {
      this.set("pageTitle", model.platformData.new ? 'Neu Platform anlegen' : 'Platform ' + model.platformData.name);
    }
  });
});