define("hc2-ui/routes/backend/integrations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    afterModel: function afterModel() {
      this.set("pageTitle", this.get("i18n").t('integrations.label'));
    },
    model: function model() {
      return this.modelFor("backend");
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.userIntegrations.get("episerver.info.clients.length") > 0) {
        controller.set("episerverClients", []);
        model.userIntegrations.get("episerver.info.clients").forEach(function (client) {
          controller.get("episerverClients").addObject({ clientId: client.clientId, name: client.name });
        });
      } else {
        controller.set("episerverClients", [{ clientId: "", name: "" }]);
      }
      if (model.userIntegrations.get("episerver.info.staticRecipientData.length") > 0) {
        controller.set("episerverStaticRecipientData", []);
        model.userIntegrations.get("episerver.info.staticRecipientData").forEach(function (data) {
          controller.get("episerverStaticRecipientData").addObject({ key: data.key, value: data.value });
        });
      } else {
        controller.set("episerverStaticRecipientData", []);
      }
      controller.set("episerverUsername", model.userIntegrations.get("episerver.info.username"));

      controller.set("inxmailCustomer", model.userIntegrations.get("inxmail.info.customer"));
      controller.set("inxmailClientId", model.userIntegrations.get("inxmail.info.clientId"));
    }
  });
});