define('hc2-ui/controllers/backend/integrations', ['exports', 'hc2-ui/config/environment', 'hc2-ui/lib/make/init'], function (exports, _environment, _init) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    api: Ember.inject.service(),
    store: Ember.inject.service(),
    apiHost: _environment.default.oauth.host,
    i18n: Ember.inject.service(),

    currentUrl: Ember.computed(function () {
      return window.encodeURIComponent(location.href);
    }),

    paypalSettings: {},
    paypalSettingsComplete: Ember.computed("paypalSettings.sandboxClientId", "paypalSettings.sandboxClientSecret", "paypalSettings.liveClientId", "paypalSettings.liveClientSecret", function () {
      if (this.get("paypalSettings.sandboxClientId") && this.get("paypalSettings.sandboxClientSecret") && this.get("paypalSettings.liveClientId") && this.get("paypalSettings.liveClientSecret")) {
        return true;
      }
      return false;
    }),

    actions: {
      saveGaTrackingId: function saveGaTrackingId() {
        var _this = this;

        this.get('store').createRecord("action", { action: "saveGaTrackingId", context: { trackingId: this.get("gaTrackingId") } }).save().then(function (data) {
          _this.set("model.userIntegrations.ga", { active: _this.get("gaTrackingId"), info: data.get("context") });
          _this.set("showGaForm", false);
        }).catch(function (r) {
          console.log(r);
        });
      },
      saveGHookUrl: function saveGHookUrl() {
        var _this2 = this;

        this.get('store').createRecord("action", { action: "saveGHookUrl", context: {
            hookApiUrl: this.get("gHookApiUrl"),
            platformId: this.get("model.platform.id")
          } }).save().then(function (data) {
          _this2.set("model.userIntegrations.gHook", { active: _this2.get("gHookApiUrl"), info: data.get("context") });
          _this2.set("showGHookForm", false);
        }).catch(function (r) {
          console.log(r);
        });
      },
      saveFacebookPixelTrackingId: function saveFacebookPixelTrackingId() {
        var _this3 = this;

        this.get('store').createRecord("action", { action: "saveFacebookTracking", context: { trackingId: this.get("facebookPixelTrackingId") } }).save().then(function (data) {
          _this3.set("model.userIntegrations.facebookPixel", { active: true, info: data.get("context") });
          _this3.set("showFacebookPixelForm", false);
        }).catch(function (r) {
          console.log(r);
        });
      },
      emarsysTestAndConnect: function emarsysTestAndConnect() {
        var _this4 = this;

        this.get('store').createRecord("action", { action: "connectToEmarsys", context: {
            username: this.get("emarsysUsername"),
            secret: this.get("emarsysSecret"),
            platformId: this.get("model.platform.id")
          } }).save().then(function (data) {
          if (data.get("context.error") === 401) {
            window.alert(_this4.get("i18n").t("emarsys.invalidCredentials"));
          } else {
            _this4.set("model.userIntegrations.emarsys", data.get("context"));
            _this4.set("showEmarsysForm", false);
          }
        }).catch(function (r) {
          console.log(r);
        });
      },
      addEpiserverClient: function addEpiserverClient() {
        var _this5 = this;

        this.get("episerverClients").addObject({ clientId: "", name: "" });
        Ember.run.scheduleOnce('afterRender', function () {
          _init.default.material(_this5.elementId);
        });
      },
      removeEpiserverClient: function removeEpiserverClient(client) {
        this.get("episerverClients").removeObject(client);
      },
      addEpiserverRecipientDataClient: function addEpiserverRecipientDataClient() {
        var _this6 = this;

        this.get("episerverStaticRecipientData").addObject({ key: "", value: "" });
        Ember.run.scheduleOnce('afterRender', function () {
          _init.default.material(_this6.elementId);
        });
      },
      removeEpiserverRecipientDataClient: function removeEpiserverRecipientDataClient(data) {
        this.get("episerverStaticRecipientData").removeObject(data);
      },
      episerverDisconnect: function episerverDisconnect() {
        var _this7 = this;

        if (window.confirm(this.get("i18n").t('confirm'))) {
          this.get('store').createRecord("action", {
            action: "connectToEpiserver", context: {
              username: null,
              platformId: this.get("model.platform.id")
            }
          }).save().then(function (data) {
            _this7.set("model.userIntegrations.episerver", data.get("context"));
            _this7.set("showEpiserverForm", false);
          }).catch(function (r) {
            console.log(r);
          }).finally(function () {
            _this7.set("connectingEpiserver", false);
          });
        }
      },
      episerverTestAndConnect: function episerverTestAndConnect() {
        var _this8 = this;

        if (this.get("connectingEpiserver")) {
          return;
        }
        this.set("connectingEpiserver", true);
        this.get('store').createRecord("action", { action: "connectToEpiserver", context: {
            username: this.get("episerverUsername"),
            password: this.get("episerverPassword"),
            clients: this.get("episerverClients"),
            staticRecipientData: this.get("episerverStaticRecipientData"),
            platformId: this.get("model.platform.id")
          } }).save().then(function (data) {
          if (!data.get("context.active")) {
            window.alert(_this8.get("i18n").t("episerver.invalidCredentials"));
          } else {
            _this8.set("model.userIntegrations.episerver", data.get("context"));
            _this8.set("showEpiserverForm", false);
          }
        }).catch(function (r) {
          console.log(r);
        }).finally(function () {
          _this8.set("connectingEpiserver", false);
        });
      },
      inxmailTestAndConnect: function inxmailTestAndConnect() {
        var _this9 = this;

        if (this.get("connectingInxmail")) {
          return;
        }
        this.set("connectingInxmail", true);
        this.get('store').createRecord("action", { action: "connectToInxmail", context: {
            customer: this.get("inxmailCustomer"),
            clientId: this.get("inxmailClientId"),
            secret: this.get("inxmailSecret"),
            platformId: this.get("model.platform.id")
          } }).save().then(function (data) {
          if (!data.get("context.active")) {
            window.alert(_this9.get("i18n").t("integrations.invalidCredentials"));
          } else {
            _this9.set("model.userIntegrations.inxmail", data.get("context"));
            _this9.set("showInxmailForm", false);
          }
        }).catch(function (r) {
          console.log(r);
        }).finally(function () {
          _this9.set("connectingInxmail", false);
        });
      },
      inxmailDisconnect: function inxmailDisconnect() {
        var _this10 = this;

        if (window.confirm(this.get("i18n").t('confirm'))) {
          this.get('store').createRecord("action", {
            action: "connectToInxmail", context: {
              username: null,
              platformId: this.get("model.platform.id")
            }
          }).save().then(function (data) {
            _this10.set("model.userIntegrations.inxmail", data.get("context"));
            _this10.set("showInxmailForm", false);
          }).catch(function (r) {
            console.log(r);
          }).finally(function () {
            _this10.set("connectingInxmail", false);
          });
        }
      },
      connectWithPaypal: function connectWithPaypal() {
        var _this11 = this;

        if (this.get("paypalSettingsComplete") && !this.get("savingPaypalSettings")) {
          this.set("paypalErrorLabel", null);
          var settings = this.get("paypalSettings");
          this.set("savingPaypalSettings", true);
          this.get("api").post("/paypal/testAndConnect", settings).then(function (data) {
            _this11.set("model.userIntegrations.paypal", { active: true, info: data });
            _this11.set("showPaypalForm", false);
          }).fail(function (r) {
            console.log(r);
            _this11.set("paypalErrorLabel", r.responseText);
          }).always(function () {
            _this11.set("savingPaypalSettings", false);
          });
        }
      }
    }
  });
});