define('hc2-ui/models/apps/contest', ['exports', 'ember-data', 'hc2-ui/models/apps/app'], function (exports, _emberData, _app) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _app.default.extend({
    headerImageUri: _emberData.default.attr("string"),

    publicQuestionsEnabled: _emberData.default.attr("boolean"),

    overlayEnabled: _emberData.default.attr("boolean"),
    overlayImageUri: _emberData.default.attr("string"),
    overlayTitleStyles: _emberData.default.attr("string"),
    backlayEnabled: _emberData.default.attr("boolean"),
    backlayImageUri: _emberData.default.attr("string"),

    participationStartDate: _emberData.default.attr("date"),
    participationEndDate: _emberData.default.attr("date"),
    votingStartDate: _emberData.default.attr("date"),
    votingEndDate: _emberData.default.attr("date"),

    totalParticipations: _emberData.default.attr('number'),
    totalParticipationsActive: _emberData.default.attr('number'),

    thanksText: _emberData.default.attr("string"),
    categories: _emberData.default.attr("csv"),

    contactData: _emberData.default.attr(),
    contactFormText: _emberData.default.attr('string'),

    acceptsPhotos: _emberData.default.attr("boolean", { defaultValue: true }),
    acceptsVideos: _emberData.default.attr("boolean"),
    acceptsMusic: _emberData.default.attr("boolean"),
    acceptsText: _emberData.default.attr("boolean"),

    mediaTypes: Ember.computed('acceptsPhotos', 'acceptsVideos', 'acceptsMusic', 'acceptsText', function () {
      var types = [];
      if (this.get("acceptsPhotos")) {
        types.pushObject("photo");
      }
      if (this.get("acceptsVideos")) {
        types.pushObject("video");
      }
      if (this.get("acceptsMusic")) {
        types.pushObject("music");
      }
      if (this.get("acceptsText")) {
        types.pushObject("text");
      }
      return types;
    }),

    endedText: _emberData.default.attr('string'),
    endedAndJuryDoneText: _emberData.default.attr('string'),

    judging: _emberData.default.attr('boolean', { defaultValue: true }),
    judgingDone: _emberData.default.attr('boolean', { defaultValue: false }),
    juryWinnerCount: _emberData.default.attr('number', { defaultValue: 3 }),
    juryWinners: _emberData.default.attr('csv'),
    votesNeedCaptcha: _emberData.default.attr('boolean', { defaultValue: true }),
    ipCheck: _emberData.default.attr('boolean', { defaultValue: true }),
    votesNeedEmailActivation: _emberData.default.attr('boolean', { defaultValue: false }),
    votesContactData: _emberData.default.attr('boolean', { defaultValue: false }),
    participationsAutoActive: _emberData.default.attr('boolean'),
    participationHintText: _emberData.default.attr('string'),
    extraMediaMax: _emberData.default.attr("number", { defaultValue: 0 }),
    maxPerLoad: _emberData.default.attr("number", { defaultValue: 12 }),
    hideVoteCount: _emberData.default.attr('boolean'),
    highlightTop: _emberData.default.attr('number'),
    highlightImageUri: _emberData.default.attr('string'),
    voteCountRestriction: _emberData.default.attr('boolean', { defaultValue: false }),
    voteCountMax: _emberData.default.attr("number", { defaultValue: 1 })
  });
});