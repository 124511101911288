define('hc2-ui/components/controls/add-to-any', ['exports', 'hc2-ui/helpers/share-url', 'hc2-ui/helpers/share-title'], function (exports, _shareUrl, _shareTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // Inspired by <!-- https://github.com/heiseonline/shariff -->

  var SERVICES = [{
    name: "Facebook",
    icon: "fa fa-facebook",
    url: "https://www.facebook.com/sharer/sharer.php?u={{URL}}",
    default: true
  }, {
    name: "X (Twitter)",
    icon: "fa fa-twitter twitter-x",
    url: "https://twitter.com/intent/tweet?text={{TEXT}}&url={{URL}}",
    default: true
  }, {
    name: "WhatsApp",
    icon: "fa fa-whatsapp bigger-icon",
    url: 'https://api.whatsapp.com/send?text={{TEXT}}%20{{URL}}',
    mobileUrl: 'whatsapp://send?text={{TEXT}}%20{{URL}}',
    default: true
  }, {
    name: "more",
    icon: "fa fa-plus",
    default: true
  }, {
    name: "E-Mail",
    icon: "fa fa-envelope",
    url: 'mailto:?subject={{TEXT}}&body={{URL}}'
  }, {
    name: "Pinterest",
    icon: "fa fa-pinterest",
    url: 'https://www.pinterest.com/pin/create/link/?url={{URL}}&description={{TEXT}}'
  }, {
    name: "Telegram",
    icon: "fa fa-send",
    url: 'https://t.me/share/url?url={{TEXT}}%20{{URL}}'
  }, {
    name: "Threema",
    icon: "fa fa-comment",
    url: 'threema://compose?text={{TEXT}}%20{{URL}}'
  }];

  exports.default = Ember.Component.extend({

    i18n: Ember.inject.service(),

    defaultServices: Ember.computed(function () {
      return SERVICES.filterBy("default", true);
    }),
    services: Ember.computed(function () {
      return SERVICES;
    }),

    actions: {
      hcShare: function hcShare(service) {
        var shareUrl = _shareUrl.default.compute([this.get("app.parentApp.id") ? this.get("app.parentApp.shareDomain") : this.get("app.shareDomain"), this.get("contentId")]);
        var shareTitle = _shareTitle.default.compute([this.get("app.parentApp.id") ? this.get("app.parentApp") : this.get("app")]);
        var url = void 0;

        if (service.name === "more") {
          this.set("showDialog", true);
        } else {

          if (navigator.userAgent.match(/iPhone|Android/i) && service.mobileUrl) {
            url = service.mobileUrl;
          } else {
            url = service.url;
          }

          if (url) {
            url = url.replaceAll("{{URL}}", encodeURIComponent(shareUrl)).replaceAll("{{TEXT}}", encodeURIComponent(shareTitle));
          }
          if (url) {
            if (window.confirm(this.get("i18n").t("loadExternalSite", [service.name]))) global.window.open(url, '_blank', 'width=600,height=460');
          }
        }
      },
      closeDialog: function closeDialog() {
        this.set("showDialog", false);
      }
    }
  });
});