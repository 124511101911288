define('hc2-ui/models/apps/app', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    appType: _emberData.default.attr("string"),
    defaultDomain: _emberData.default.attr("string"),
    config: _emberData.default.attr(),
    questions: _emberData.default.attr(),
    contactData: _emberData.default.attr(),

    active: _emberData.default.attr("boolean"),
    accessCodeActive: _emberData.default.attr("string"),
    allowZipExport: _emberData.default.attr("boolean"),
    editable: _emberData.default.attr("boolean"),

    title: _emberData.default.attr("string"),
    showTitle: _emberData.default.attr("boolean"),
    showHeader: _emberData.default.attr("boolean"),
    conditions: _emberData.default.attr("string"),
    conditionsLabel: _emberData.default.attr("string"),
    impressum: _emberData.default.attr("string"),
    impressumLabel: _emberData.default.attr("string"),
    privacyPolicy: _emberData.default.attr("string"),
    startDate: _emberData.default.attr("date"),
    endDate: _emberData.default.attr("date"),
    mainImage: _emberData.default.attr("string"),

    description: _emberData.default.attr("string"),
    descriptionHeader: _emberData.default.attr("string"),

    additionalInformation: _emberData.default.attr("string"),
    additionalInformationHeader: _emberData.default.attr("string"),

    footer: _emberData.default.attr("string"),
    logoUri: _emberData.default.attr("string"),

    welcomePage: _emberData.default.attr("boolean"),
    welcomePageImgUri: _emberData.default.attr("string"),
    welcomePageText: _emberData.default.attr("string"),
    welcomePageNextBtnText: _emberData.default.attr("string"),
    showCountdown: _emberData.default.attr("boolean"),

    fangateActive: _emberData.default.attr("boolean"),
    facebookComments: _emberData.default.attr("boolean"),
    facebookLikeUrl: _emberData.default.attr("string"),
    facebookPageId: _emberData.default.attr("string"),
    embedUrl: _emberData.default.attr('string'),

    sharingTitle: _emberData.default.attr('string'),
    sharingDescription: _emberData.default.attr('string'),
    sharingImageUri: _emberData.default.attr('string'),
    showSharingButtons: _emberData.default.attr("boolean", { defaultValue: true }),

    participateLabel: _emberData.default.attr('string'),
    closeBtnText: _emberData.default.attr('string'),
    nextBtnText: _emberData.default.attr('string'),
    prevBtnText: _emberData.default.attr('string'),

    customStyles: _emberData.default.attr('string'),

    owner: _emberData.default.belongsTo('user'),
    childAppIndex: _emberData.default.attr('number', { defaultValue: 0 }),
    childApps: _emberData.default.hasMany('apps/app', { polymorphic: true, async: true }),
    childAppDates: _emberData.default.attr(),
    parentApp: _emberData.default.belongsTo('apps/app', { polymorphic: true, async: true, inverse: 'childApps' }),

    demo: _emberData.default.attr("boolean"),

    translations: _emberData.default.attr('object', { defaultValue: function defaultValue() {
        return {};
      } }),

    defaultLocale: _emberData.default.attr('string', { defaultValue: "de" }),
    locales: _emberData.default.attr('csv', { defaultValue: function defaultValue() {
        return [{ value: 'de' }];
      } }),

    appIntegrations: _emberData.default.belongsTo('app-integration', { async: true }),
    customDomain: _emberData.default.belongsTo('app-domain', { async: true }),

    localesParent: Ember.computed("parentApp.locales", "locales", function () {
      if (this.get("parentApp.locales")) {
        return this.get("parentApp.locales");
      } else {
        return this.get("locales");
      }
    }),
    defaultLocaleParent: Ember.computed("parentApp.defaultLocale", "defaultLocale", function () {
      if (this.get("parentApp.defaultLocale")) {
        return this.get("parentApp.defaultLocale");
      } else {
        return this.get("defaultLocale");
      }
    }),

    dragEnabled: _emberData.default.attr("boolean"),
    emailSender: _emberData.default.attr('string'),
    emailNotifications: _emberData.default.attr('string'),
    participationDOI: _emberData.default.attr('boolean'),
    hookApiUrl: _emberData.default.attr('string'),

    lastUpdated: _emberData.default.attr("date"),
    dateCreated: _emberData.default.attr("date"),

    shareDomain: Ember.computed("defaultDomain", "customDomain.domain", function () {
      var domain = this.get("defaultDomain");
      var customDomain = this.get("customDomain.domain");
      var shareUrl = customDomain ? customDomain : domain;
      return shareUrl;
    }),

    gaTracker: _emberData.default.attr("string"),
    gaTrackingId: _emberData.default.attr("string"),
    facebookPixelId: _emberData.default.attr("string"),

    consentGate: _emberData.default.attr('boolean', { defaultValue: true }),
    cookieBanner: _emberData.default.attr('boolean'),
    cookieBannerAsOverlay: _emberData.default.attr('boolean'),
    dontSaveFormData: _emberData.default.attr('boolean'),
    needsThirdPartyOptIn: _emberData.default.attr('boolean'),
    participationsNeedCaptcha: _emberData.default.attr('boolean'),
    externalUUID: _emberData.default.attr('boolean'),
    hasMailings: Ember.computed("appType", function () {
      return ["christmasCalendar", "calendar"].includes(this.get("appType"));
    })
  });
});