define("hc2-ui/models/platform", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    availableApps: _emberData.default.attr('string'),
    appId: _emberData.default.attr("number"),
    appType: _emberData.default.attr("string"),
    inactiveAppMessage: _emberData.default.attr("string")
  });
});