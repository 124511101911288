define('hc2-ui/components/controls/file-upload', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberUploader.default.FileField.extend({

        config: Ember.inject.service(),
        session: Ember.inject.service(),
        uploadFinished: "uploadFinished",
        uploadFailed: "uploadFailed",
        allowedExtentions: {
            images: ['jpg', 'jpeg', 'png', 'bmp', 'gif'],
            wincodes: ['txt', 'csv']
        },
        extentionType: 'images',

        uploadQueryString: null,

        filesDidChange: function filesDidChange(files) {
            this.set("uploadStatus", {});
            var uploadHost = this.get('config').getENV().oauth.host;
            var uploadUrl = uploadHost + '/upload/file';
            var uploadQueryString = this.get("uploadQueryString");
            if (uploadQueryString) {
                uploadUrl += "?" + uploadQueryString;
            }
            var accessToken = this.get('session.data.authenticated.access_token');
            if (accessToken) {
                // baearer header in AuthorizedUploader seams not to work
                // maybe overridden by app.js with x-hc headers
                // fix by passing as query param
                var atp = "";
                atp += uploadUrl.indexOf("?") > -1 ? "&" : "?";
                atp += "access_token=" + accessToken;
                uploadUrl += atp;
            }

            var AuthorizedUploader = _emberUploader.default.Uploader.extend({
                ajaxSettings: function ajaxSettings() {
                    var settings = this._super.apply(this, arguments);
                    if (accessToken) {
                        settings.headers = {
                            'Authorization': 'Bearer ' + accessToken
                        };
                    }
                    return settings;
                }
            });
            var uploader = AuthorizedUploader.create({
                url: uploadUrl
            });
            var that = this;
            uploader.on('progress', function (e) {
                that.set("uploadStatus.progress", e.percent);
            });

            if (!Ember.isEmpty(files)) {
                // check extension
                var extention = files[0].name.toLowerCase().substring(files[0].name.lastIndexOf('.') + 1);
                var allowedExtentions = this.get('allowedExtentions.' + this.get('extentionType'));
                var validExtention = !Array.isArray(allowedExtentions) || allowedExtentions.indexOf(extention) > -1;

                // set status
                that.set("uploadStatus.invalidExtension", !validExtention);
                that.set("uploadStatus.isUploading", validExtention);
                // start upload
                if (validExtention) {
                    uploader.upload(files[0]).then(function (data) {
                        that.set("uploadStatus.isUploading", false);
                        that.sendAction('uploadFinished', data.message);
                    }, function (data) {
                        that.set("uploadStatus.isUploading", false);
                        that.sendAction('uploadFailed', data);
                    });
                }
            }
        }
    });
});