define('hc2-ui/components/controls/vimeo-upload', ['exports', 'ember-uploader'], function (exports, _emberUploader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberUploader.default.FileField.extend({

    config: Ember.inject.service(),
    session: Ember.inject.service(),
    uploadFinished: "uploadFinished",
    uploadFailed: "uploadFailed",
    allowedExtentions: {
      video: ['mp4', 'mov', 'wmv', 'avi', 'flv', 'ogg', 'mpg', 'rm', 'mpeg', 'mpeg4', 'mp4v', 'mpe', 'mpg', 'm4a', 'H.264', 'mpg4']
    },
    extentionType: 'video',
    maxFileSize: 1000, // MB

    filesDidChange: function filesDidChange(files) {
      var _this2 = this;

      if (!Ember.isEmpty(files)) {
        var file = files[0];

        // check extension
        var extention = file.name.toLowerCase().substring(file.name.lastIndexOf('.') + 1);
        var allowedExtentions = this.get('allowedExtentions.' + this.get('extentionType'));
        var validExtention = !Array.isArray(allowedExtentions) || allowedExtentions.indexOf(extention) > -1;
        var filesize = file.size;
        var filesizeInMb = file.size / 1024 / 1024;
        var filesizeExceeded = filesizeInMb > this.get("maxFileSize") ? filesizeInMb.toFixed(2) + "/" + this.get("maxFileSize") + "MB" : false;

        // set status
        this.set("uploadStatus", {});
        this.set("uploadStatus.invalidExtension", !validExtention);
        this.set("uploadStatus.filesizeExceeded", filesizeExceeded);
        this.set("uploadStatus.isUploading", validExtention && !filesizeExceeded);

        // start upload
        if (validExtention && !filesizeExceeded) {

          var uploadHost = this.get('config').getENV().oauth.host;

          Ember.$.get(uploadHost + "/upload/vimeo?filesize=" + filesize).then(function (vimeoUploadData) {

            var AuthorizedUploader = _emberUploader.default.Uploader.extend({
              ajaxSettings: {
                headers: {
                  "TUS-Resumable": "1.0.0",
                  "Upload-Offset": "0",
                  "Content-Type": "application/offset+octet-stream"
                }
              }
            });
            var uploader = AuthorizedUploader.create({
              url: vimeoUploadData.vimeoUploadLink,
              paramName: 'file-data',
              method: 'PATCH',
              upload: function upload(files) {
                var _this = this;

                var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

                return new Ember.RSVP.Promise(function (resolve, reject) {
                  var reader = new FileReader();

                  reader.onload = function (e) {
                    resolve(e.target.result);
                  };

                  reader.onerror = function () {
                    reject(this);
                  };

                  reader.readAsArrayBuffer(files);
                }).then(function (data) {
                  var url = _this.get('url');
                  var method = _this.get('method');

                  _this.set('isUploading', true);

                  return _this.ajax(url, data, method);
                });
              }
            });

            var that = _this2;
            uploader.on('progress', function (e) {
              that.set("uploadStatus.progress", e.percent);
            });

            uploader.upload(file).then(function (data) {

              // verify uploaded data
              Ember.$.ajax({ method: 'HEAD', url: vimeoUploadData.vimeoUploadLink,
                headers: { "Tus-Resumable": "1.0.0", "Accept": "application/vnd.vimeo.*+json;version=3.4" } }).then(function (undef, nocontent, verifyResponse) {

                var uploadLength = verifyResponse.getResponseHeader("Upload-Length");
                var uploadOffset = verifyResponse.getResponseHeader("Upload-Offset");

                if (uploadLength == uploadOffset) {
                  // complete upload
                  var title = that.get("namePrefix") + file.name;
                  Ember.$.ajax({
                    method: 'GET', url: uploadHost + "/upload/vimeoCompleteUpload",
                    data: { videoLink: vimeoUploadData.videoLink, title: title }
                  }).then(function (data) {
                    that.set("uploadStatus.isUploading", false);
                    var objectUrl = window.URL.createObjectURL(file);
                    that.sendAction('uploadFinished', data.videoUrl, objectUrl);
                  });
                }
              });
            }, function (data) {
              // error on first put
              _this2.set("uploadStatus.isUploading", false);
              _this2.sendAction('uploadFailed', data);
            });
          });
        }
      }
    }
  });
});