define("hc2-ui/lib/constants/all-app-labels", ["exports", "hc2-ui/components/apps/sweepstake/editor-form", "hc2-ui/components/apps/win-wheel/editor-form", "hc2-ui/components/apps/test/editor-form", "hc2-ui/components/apps/slot-machine/editor-form", "hc2-ui/components/apps/scratch-card/editor-form", "hc2-ui/components/apps/quiz/editor-form", "hc2-ui/components/apps/poll/editor-form", "hc2-ui/components/apps/pixel-graphic/editor-form", "hc2-ui/components/apps/pack-your-bag/editor-form", "hc2-ui/components/apps/memory/editor-form", "hc2-ui/components/apps/game/editor-form", "hc2-ui/components/apps/contest/editor-form", "hc2-ui/components/apps/code-promotion/editor-form", "hc2-ui/components/apps/christmas-calendar/editor-form", "hc2-ui/components/apps/calendar/editor-form"], function (exports, _editorForm, _editorForm2, _editorForm3, _editorForm4, _editorForm5, _editorForm6, _editorForm7, _editorForm8, _editorForm9, _editorForm10, _editorForm11, _editorForm12, _editorForm13, _editorForm14, _editorForm15) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getAllAppLabels = getAllAppLabels;
  function getAllAppLabels() {
    var labs = new Map();
    labs.set("sweepstake", new _editorForm.default().get("editableLabels"));
    labs.set("contest", new _editorForm12.default().get("editableLabels"));
    labs.set("christmasCalendar", new _editorForm14.default().get("editableLabels"));
    labs.set("calendar", new _editorForm15.default().get("editableLabels"));
    labs.set("quiz", new _editorForm6.default().get("editableLabels"));
    labs.set("poll", new _editorForm7.default().get("editableLabels"));
    labs.set("pixelGraphic", new _editorForm8.default({ appStates: {} }).get("editableLabels"));
    labs.set("test", new _editorForm3.default().get("editableLabels"));
    labs.set("winWheel", new _editorForm2.default().get("editableLabels"));
    labs.set("slotMachine", new _editorForm4.default().get("editableLabels"));
    labs.set("scratchCard", new _editorForm5.default().get("editableLabels"));
    labs.set("packYourBag", new _editorForm9.default().get("editableLabels"));
    labs.set("memory", new _editorForm10.default({ appStates: {} }).get("editableLabels"));
    labs.set("game", new _editorForm11.default({ appStates: {} }).get("editableLabels"));
    labs.set("codePromotion", new _editorForm13.default().get("editableLabels"));

    var keys = Array.from(labs.keys());

    var labApp = [];

    keys.forEach(function (k) {
      var li = labs.get(k);
      li.forEach(function (l) {

        var ex = labApp.find(function (e) {
          return e.label === l;
        });
        var apps = ex ? ex.apps : [];
        apps.push(k);
        if (!ex) {
          ex = { label: l, apps: apps };
          labApp.push(ex);
        }
      });
    });

    labApp.forEach(function (la) {
      if (la.apps.length === 15) {
        la.apps = undefined;
      }
    });

    return labApp;
  }
});