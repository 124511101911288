define('hc2-ui/components/apps/emails-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'emails-editor-form',
    editableNewsletterDOIEmailLabels: ["emails_doi_subject", "emails_doi_text", "emails_doi_landingPage_text"],
    editableNewsletterDOIEmailLabelsWithEditor: ["emails_doi_text", "emails_doi_landingPage_text"],

    editableParticipationDOIEmailLabels: ["emails_participationDOI_subject", "emails_participationDOI_text", "emails_participationDOI_landingPage_text"],
    editableParticipationDOIEmailLabelsWithEditor: ["emails_participationDOI_text", "emails_participationDOI_landingPage_text"],

    editableNotificationsLabels: ["emails_notifications_subject", "emails_notifications_text"],
    editableNotificationsLabelsWithEditor: ["emails_notifications_text"],

    editableDoiNotificationsLabels: ["emails_doiNotifications_subject", "emails_doiNotifications_text"],
    editableDoiNotificationsLabelsWithEditor: ["emails_doiNotifications_text"],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('showNotifications', this.get('appData.emailNotifications'));
      this.set('showDoiNotifications', this.get('appData.config.doiNotificationRecipients'));
    },

    actions: {
      toogleNotifications: function toogleNotifications(event) {
        if (!event.target.checked) {
          this.set("appData.emailNotifications", null);
        }
        this.set("showNotifications", event.target.checked);
      },
      toogleDoiNotifications: function toogleDoiNotifications(event) {
        if (!event.target.checked) {
          this.set("appData.config.doiNotificationRecipients", null);
        }
        this.set("showDoiNotifications", event.target.checked);
      }
    }

  });
});